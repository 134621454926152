import styled from 'styled-components'


export const ContainerC = styled.div`
    width:100%;
    .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
        overflow: hidden !important;
        &::-webkit-scrollbar {
            width: 1em !important;
        }
        
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
        }
        
        &::-webkit-scrollbar-thumb {
            background-color: darkgrey !important;
            outline: 1px solid slategrey !important;
        }
    }
`


export const WrapAll = styled.div`
     overflow: scroll;
     overflow-x: hidden;
     width: 100%;
     min-width: 500px;
        &::-webkit-scrollbar {
            width: 0.5em !important;
        }
        
        &::-webkit-scrollbar-thumb {
            background-color: blue !important;
            border-radius: 4px;
        }
`