import { Button, Modal } from "antd";
import React, { useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Progress, Tooltip } from "antd";
import {
  AnsText,
  AnswerBox,
  AnswerDiv,
  AnswerScores,
  Centering,
  Centering1,
  Circle,
  Container,
  Dumaloq,
  DumaloqText,
  DumaloqTitle,
  NextButton,
  PercentBox,
  PercentText,
  ProgressWrap,
  QuestionText,
  ResultBox,
  ScoreShowText,
  TestTitle,
  TextBox,
  TextBox1,
} from "./modalstyle";
import {
  Box,
  LinearProgress,
} from "@mui/material";
import { TestDATA } from "./data";
import {CustomizedProgressBars,CustomizedProgressBars2} from './loading'
import useAxios from "../../../../api/useAxios";
import { useEffect } from "react";
import { CountdownCircleTimer ,useCountdown } from 'react-countdown-circle-timer'
import { MyBox, TimerMain, TimerText } from "./style";
import CountDown from "./CountDown/CountDown";
import { MathJax } from 'better-react-mathjax';


const TestModal = (
  {setStartTimerDown,
   StartTimerDown,
   modal2Visible, setModal2Visible,
   QuizTime,QuizID,render,setrender,QuizHeader,
   isResultLoading,setisResultLoading,Result,setResult,
   persentnum,setpersentnum,TestIndex,setTestIndex,setQuizTime
  }) => {
  const [choosen, setchoosen] = useState('');
  const [isloading, setisloading] = useState(false)
  const [btnWord, setbtnWord] = useState('Keyingi');
  const api = useAxios();
  let bgcolor = 'linear-gradient(180deg, #F17120 0%, #F27221 51.04%, #D75706 100%)'
  const Base_style = localStorage.getItem('maktab') !== null ? localStorage.getItem('maktab') : '0';
  const [TestData, setTestData] = useState([]);
  const [AnswersData, setAnswersData] = useState([]);
  const [HowManyTime, setHowManyTime] = useState(null);

  function GetPercent(len,mul) {
    setpersentnum(Math.round((100/len)*mul)) 
  }

  
  const GetQuizQuestions = async () => {
    try {
      const res = await api.get(`/quiz/questions/?quiz=${QuizID}`,{params:QuizID})
      if(res.data.success){
        // console.log("questions",res);
        setTestData(res.data.data)
      }
    } catch (err) {}
  }
  
  useEffect(() => {
    GetQuizQuestions()
  }, [render]);


  

  function WordCalculation(mark) {
    if(mark>28 && mark<45){
      return "Boshlang'ich"
    }else if(mark>=45 && mark<65){
      return "O'rta"
    }else if(mark>=65 && mark<=100){
      return "Yuqori"
    }else{
      return "Juda past"
    }
  }

  
  

  function Choosee(selected_answer,question_id) {
   
    let isContain = AnswersData?.filter((item)=>{
      return item.question == question_id
    })

    if(isContain?.length==0){
      setAnswersData([...AnswersData ,{question:question_id,answer:selected_answer}]);
    }else{
      setAnswersData(AnswersData.pop())
      setAnswersData([...AnswersData ,{question:question_id,answer:selected_answer}]);
    }
    setchoosen(selected_answer)
    GetPercent(TestData?.length,TestIndex+1)
  }

  function HandleSubmitTest() {
    let formData = {
      quiz:QuizID,
      time:HowManyTime,
      answers:AnswersData
    }

    api.post(`/quiz/result/`,formData).then((res)=>{
        if(res.data.success){
          // console.log("answers",res);
          setResult(res.data.data)
          setisResultLoading('result')
          setbtnWord('Tugatish')
          setAnswersData([])
          setModal2Visible(false)
          setTestIndex(0)
          setResult({})
          setpersentnum(0)
          setHowManyTime(null)
          setrender(!render)
          setStartTimerDown(prev=>prev +1)
          // window.location.reload()
          // setTimeout(() => {
            // }, 1000);
        }
    })
  }

  function NextTest() {
    if(isResultLoading==='result'){
      setModal2Visible(false)
      setTestIndex(0)
      setResult({})
      setpersentnum(0)
      setrender(!render)
      // window.location.reload()
    }else{
      if(TestIndex+1<TestData?.length){
        setisloading(true)
        setchoosen('')
        setTestIndex(TestIndex+1)
        
      }else{
        setisResultLoading('check')
        setbtnWord('Tekshirilmoqda...')
        let formData = {
          quiz:QuizID,
          time:Math.round(HowManyTime/60)+1,
          answers:AnswersData
        }

        // console.log("going data",formData);
        api.post(`/quiz/result/`,formData).then((res)=>{
            if(res.data.success){
              // console.log("answers",res);
              setResult(res.data.data)
              setisResultLoading('result')
              setbtnWord('Tugatish')
              setAnswersData([])
              setStartTimerDown(prev=>prev +1)
              setQuizTime(0)
              setHowManyTime(null)
              // setTimeout(() => {
              // }, 1000);
            }
        })
      }
      setTimeout(() => {
        setisloading(false)
      }, 500);
    }
  }

  return (
    <>
      <Modal
        centered
        visible={modal2Visible}
        closeIcon={
          <CloseRoundedIcon
            style={{
              marginRight: -10,
              fontSize: 30,
              color: "#B34714",
              marginTop: 7,
            }}
            onClick={() => {
              setModal2Visible(false);
              setTestIndex(0)
              setchoosen('')
              setResult({})
              setpersentnum(0)
              window.location.reload()

            }}
          />
        }
        onOk={() => setModal2Visible(false)}
        footer={false}
        width={700}
      >
       <TimerMain>
        <CountDown
         setStartTimerDown={setStartTimerDown} 
         StartTimerDown={StartTimerDown} 
         QuizTime={QuizTime} 
         HandleSubmitTest={HandleSubmitTest} 
         setHowManyTime={setHowManyTime}
        />
        
       </TimerMain>
          <Container>
          {isResultLoading==='result' ? '' : <ProgressWrap Base_style={Base_style} >
            <Tooltip title={`${TestIndex} tasi bajarildi / jami ${TestData?.length} tadan`}>
              <Box sx={{ width: "87%" }}>
                <LinearProgress
                  style={{
                    height: 13,
                    borderRadius: 10,
                    backgroundColor: "#D9F6FF",
                  }}
                  variant="determinate"
                  value={persentnum}
                />
              </Box>
            </Tooltip>
            <Progress
              type="circle"
              trailColor="white"
              width={40}
              percent={persentnum}
            />
          </ProgressWrap>
          }
          <TestTitle>
            {
              isResultLoading === 'check' ? "Natijangiz tekshirilmoqda" :
              isResultLoading === 'test' ? QuizHeader : QuizHeader
            }
          </TestTitle>
          <Centering>
            {
              isResultLoading === 'check' ?
               <CustomizedProgressBars2 />
                :
              isResultLoading === 'test' ? 
              <TextBox Base_style={Base_style}>
                { isloading   ? 
                  <CustomizedProgressBars />
                  :
                  <div>
                    <MathJax style={{display:'flex',justifyContent:'flex-start',alignItems:'flex-start'}}>
                      <QuestionText>
                        {TestIndex+1}.
                      </QuestionText>
                     <QuestionText dangerouslySetInnerHTML={{ __html: TestData[TestIndex]?.query }}>
                     
                      </QuestionText>
                    </MathJax>
                    <AnswerBox>
                      <AnswerDiv 
                          Base_style={Base_style} 
                          isSelected={choosen==TestData[TestIndex]?.optionA ? '2px solid rgba(241, 113, 32, 1)' : ''} 
                          onClick={()=>Choosee(TestData[TestIndex]?.optionA,TestData[TestIndex].id)} 
                        >
                        <Circle Base_style={Base_style} isSelected={choosen==TestData[TestIndex]?.optionA  ? bgcolor : ''}></Circle>
                        <MathJax style={{width:'70%'}}>
                        <AnsText dangerouslySetInnerHTML={{ __html: TestData[TestIndex]?.optionA  }}></AnsText>
                        </MathJax>
                      </AnswerDiv>
                      <AnswerDiv
                         Base_style={Base_style}
                         isSelected={choosen==TestData[TestIndex]?.optionB ? '2px solid rgba(241, 113, 32, 1)' : ''}
                         onClick={()=>Choosee(TestData[TestIndex]?.optionB,TestData[TestIndex].id)}
                          >
                        <Circle Base_style={Base_style} isSelected={choosen==TestData[TestIndex]?.optionB  ? bgcolor : ''}></Circle>
                          <MathJax style={{width:'70%'}}>
                            <AnsText dangerouslySetInnerHTML={{ __html: TestData[TestIndex]?.optionB }}></AnsText>

                          </MathJax>
                      </AnswerDiv>
                      <AnswerDiv
                        Base_style={Base_style}
                        isSelected={choosen==TestData[TestIndex]?.optionC ? '2px solid rgba(241, 113, 32, 1)' : ''} 
                        onClick={()=>Choosee(TestData[TestIndex]?.optionC,TestData[TestIndex].id)} 
                        >
                        <Circle Base_style={Base_style} isSelected={choosen==TestData[TestIndex]?.optionC  ? bgcolor : ''}></Circle>
                       <MathJax style={{width:'70%'}}>
                         <AnsText dangerouslySetInnerHTML={{ __html: TestData[TestIndex]?.optionC }}></AnsText>
                       </MathJax>

                      </AnswerDiv>
                      <AnswerDiv
                        Base_style={Base_style}
                        isSelected={choosen==TestData[TestIndex]?.optionD ? '2px solid rgba(241, 113, 32, 1)' : ''} 
                        onClick={()=>Choosee(TestData[TestIndex]?.optionD,TestData[TestIndex].id)} 
                        >
                        <Circle Base_style={Base_style} isSelected={choosen==TestData[TestIndex]?.optionD  ? bgcolor : ''}></Circle>
                       <MathJax style={{width:'70%'}}>
                       <AnsText dangerouslySetInnerHTML={{ __html: TestData[TestIndex]?.optionD }}></AnsText>
                       </MathJax>

                      </AnswerDiv>
                    </AnswerBox>
                  </div>
                }
              </TextBox>
              : <ResultBox>
                  <PercentBox>
                      <Dumaloq>
                        <DumaloqText>{Result?.correct}/{TestData?.length}</DumaloqText>
                        <DumaloqTitle>{Result?.mark}%</DumaloqTitle>
                      </Dumaloq>
                      <PercentText>{WordCalculation(Result?.mark)}</PercentText>
                  </PercentBox>
                  <AnswerScores>
                    <ScoreShowText isThat={Result?.mark > 28 && Result?.mark < 45}>28%-45% <br /> Boshlang’ich</ScoreShowText>
                    <ScoreShowText isThat={Result?.mark >= 45 && Result?.mark < 65}>45%-65% <br /> O’rta</ScoreShowText>
                    <ScoreShowText isThat={Result?.mark >= 65  && Result?.mark <= 100}>65%-100% <br /> Yuqori</ScoreShowText>
                  </AnswerScores>
                </ResultBox>
            }
          </Centering>
          <Centering1>
                  <NextButton  Base_style={Base_style} onClick={NextTest}>{btnWord}</NextButton>
          </Centering1>
        </Container>
      </Modal>
    </>
  );
};

export default TestModal;
