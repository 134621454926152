import styled from "styled-components";
import BgImg from "../../../images/homebg.png";
import BgImg2 from "../../../images/homebg2.svg";

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1b013a;
  position: relative;
  z-index: 9999999;
  padding-top: 180px;
`;

export const Wrapper = styled.div`
  width: 97%;
  max-width: 1600px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* height: calc(100vh - 430px); */
  height: auto;
  @media (max-width: 1300px) {
    flex-direction: column;
  }
`;

export const LeftWrapper = styled.div`
  width: 49%;
  /* border: 1px solid yellow; */
  z-index: 99999;
  margin-top: -40px;
  @media (max-width: 1300px) {
    width: 95%;
    margin-top: 0px;
  }
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;
export const RightWrapper = styled.div`
  width: 49%;
  /* border: 1px solid blue; */
  height: 700px;
  background-image: url(${BgImg});
  background-repeat: no-repeat;
  background-size: contain;

  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1300px) {
    margin-top: 20px;
    width: 95%;
    height: auto;
  }
`;
export const BigText = styled.h1`
  font-size: 36px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 0em;
  color: #ffffff;
  @media (max-width: 768px) {
    text-align: center;
  }
  @media (max-width: 425px) {
    font-size: 24px;
    line-height: normal;
  }
`;
export const Br = styled.br``;

export const MainBgButton = styled.button`
  background: #3e3fc4;
  font-size: 56px;
  font-weight: 400;
  line-height: 70px;
  letter-spacing: 0em;
  color: white;
  border: none;
  border-radius: 5px;
  padding-left: 13px;
  padding-right: 13px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  @media (max-width: 768px) {
    text-align: center;
  }
  @media (max-width: 460px) {
    font-size: 45px;
    line-height: 50px;
  }
`;
export const MainBgButton1 = styled.button`
  font-size: 56px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 0em;
  background: none;
  color: white;
  border: none;
  border-radius: 5px;
  padding-left: 13px;
  padding-right: 13px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  @media (max-width: 768px) {
    text-align: center;
  }
  @media (max-width: 460px) {
    font-size: 45px;
    line-height: 50px;
  }
`;
export const MiddleText = styled.h3`
  font-family: Lexend;
  font-size: 36px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  margin-top: 15px;
  @media (max-width: 768px) {
    text-align: center;
  }
  @media (max-width: 460px) {
    font-size: 25px;
    line-height: 30px;
    margin-top: 40px;
  }
`;
export const OnlinePlatform = styled.span`
  font-family: Lexend;
  font-size: 36px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 460px) {
    font-size: 25px;
    line-height: 30px;
  }
`;
export const SmallText = styled.div`
  font-family: Lexend;
  font-size: 22px;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0em;
  color: #ffffff;
  margin-top: 40px;
  @media (max-width: 768px) {
    text-align: center;
  }
  @media (max-width: 460px) {
    font-size: 19px;
    line-height: 30px;
  }
`;
export const BatafsilButton = styled.a`
  background: #3e3fc4;
  font-size: 24px;
  font-weight: 400;
  line-height: 70px;
  letter-spacing: 0em;
  color: white;
  border: none;
  border-radius: 5px;
  padding-left: 13px;
  padding-right: 13px;
  height: 62px;
  width: 214px;
  position: relative;
  border-radius: 7.6715240478515625px;
  margin-top: 49px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 460px) {
    font-size: 19px;
    line-height: 30px;
    height: 42px;
    width: 184px;
  }

  :before {
    content: "";
    background: linear-gradient(
      45deg,
      #ff0000,
      #ff7300,
      #fffb00,
      #48ff00,
      #00ffd5,
      #34f5e2,
      #4a4a4a,
      #ff00c8,
      #ff0000
    );
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }
  :active {
    color: #00fff7;
  }

  :active:after {
    background: transparent;
  }

  :hover:before {
    opacity: 1;
  }

  :after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
`;

export const ImgContainer = styled.div`
  width: 100%;
  height: 100%;
  background: url(${BgImg2});
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
    background: none;
    padding-bottom: 25px;
  }
`;

export const ImgHome1 = styled.img`
  height: 390px;
  width: 205.80596923828125px;
  border-radius: 7.763681411743164px;
  object-fit: cover;
  margin: 5px;
  margin-top: 50px;
  /* outline:2px solid #420AA0; */
  @media (max-width: 768px) {
    width: 70%;
    margin-top: 10px;
    display: none;
  }
`;
export const ImgHome2 = styled.img`
  height: 390px;
  width: 205.80596923828125px;
  border-radius: 7.763681411743164px;
  object-fit: cover;
  margin: 5px;
  /* outline:2px solid #420AA0; */
  @media (max-width: 768px) {
    width: 70%;
    margin-top: 10px;
  }
`;
export const ImgHome3 = styled.img`
  height: 390px;
  width: 205.80596923828125px;
  border-radius: 7.763681411743164px;
  object-fit: cover;
  margin: 5px;
  margin-top: -50px;
  /* outline:2px solid #420AA0; */
  @media (max-width: 768px) {
    width: 70%;
    margin-top: 10px;
    display: none;
  }
`;

export const BlurDiv = styled.div`
  position: absolute;
  width: 1020px;
  height: 1020px;
  left: -400px;
  top: -400px;

  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(134, 3, 182, 0.5) 0%,
    rgba(195, 26, 255, 0) 100%
  );
  @media (max-width: 768px) {
    width: 100%;
    height: 900px;
    left: -100px;
    top: -300px;
  }
`;
