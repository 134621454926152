import React, { useState } from 'react';
import { Progress, Modal } from 'antd';
import { SyncOutlined, MoreOutlined } from '@ant-design/icons';
import { Button } from "@mui/material";
import { Dropdown, Menu } from "antd";
import { Arrow_parent, UserPic } from '../../assets/icons';
import Footer from './Footer';
import axios from 'axios';
import { BASE_URL } from '../../constants/base_url';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Parents = () => {

  var token = JSON.parse(localStorage.getItem("parent_token"))
  var Parent = JSON.parse(localStorage.getItem("user_parent"))

  const [errorText, seterrorText] = useState('');
  const [num, setNum] = useState(0);
  const [modal, setModal] = useState(false);
  const [load, setLoad] = useState(false);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const okText = load ? <SyncOutlined spin /> : "Saqlash"

  const [Childs, setChilds] = useState([]);
  const [ChildCourses, setChildCourses] = useState([]);
  const [ChildQuezs, setChildQuezs] = useState([]);
  
  const handleModal = () => {
    setModal(!modal)
  }
  // console.log(token.access)
  const handleOk = () => {
    setLoad(true)
    const data ={
      username: username,
      password: password
    }
    axios.post(`${BASE_URL}/api/v1/parent/mychild/`, data, {
      headers: {
        Authorization: "Bearer " + token?.access,
      },
    })
    .then((res)=>{
      // console.log(res)
      if(res.data.success){
        setTimeout(() => {
          setLoad(false)
          setModal(false)
          getChilds()
          setPassword("")
          setUsername("")
        }, 1000);
      }else{
        seterrorText(res.data.error)
        setLoad(false)
      }
    })
  };

  const handleFarzand = (p) => {
    axios.get(`${BASE_URL}/api/v1/parent/child-result/${p}`, {
      headers: {
        Authorization: "Bearer " + token?.access,
      },
    }).then((res)=>{
      // console.log(res)
      setChildCourses(res.data.data.courses)
      setChildQuezs(res.data.data.quizs)
    })
    setNum(p)
  }
  const getChilds = () => {
    axios.get(`${BASE_URL}/api/v1/parent/mychild/`, {
      headers: {
        Authorization: "Bearer " + token?.access,
      },
    }).then((res)=>{
      // console.log(res)
      setChilds(res.data.data)
    })
  }
  const deleteChild = (p) => {
    axios.delete(`${BASE_URL}/api/v1/parent/mychild/${p}/`, {
      headers: {
        Authorization: "Bearer " + token?.access,
      },
    }).then((res)=>{
      // console.log(res)
      getChilds()
      setChildCourses([])
      setChildQuezs([])
    })
  }
  
  const logOutUser = () => {
    localStorage.removeItem('user_parent')
    localStorage.removeItem('parent_token')
    navigator('/')
  }
  const menu = (
    <Menu
      className='k__mobile'
      items={[
        {
          label: <Link to="/" onClick={logOutUser}>Chiqish</Link>,
          key: "0"
        },
      ]}
    />
  );
  
  useEffect(()=>{
    getChilds()
    if (token === null) {
      navigator('/')
    }
  },[num])
  // console.log(token)

  return (
    <React.Fragment>
      <div className="parents">
        <div className="parents__header">
          <div className="parents__nav">
            <div>
              <h1>Farzandlarim</h1>
              <Button variant="contained" onClick={()=>handleModal()}>Qo’shish</Button>
              <Modal
                title="Farzand qo’shish"
                visible={modal}
                closable={true}
                // okText="Saqlash"
                okText={okText}
                onOk={handleOk}
                onCancel={handleModal}
                className={`password__change password__4`}
                
              >
                <p style={{textAlign:"center"}}>Login</p>
                <span style={{color:'red',wordBreak:'break-word',fontSize:13,width:'300px'}}>{errorText}</span>
                <input 
                  value={username}
                  type="text"  style={{textAlign:"center"}} 
                  onChange={(e)=>{
                    setUsername(e.target.value)
                    seterrorText('')
                  }} 
                  placeholder='Login'
                />
                <p style={{textAlign:"center"}}>Parol</p>
                <input 
                  value={password}
                  type="password"  style={{textAlign:"center"}}
                  onChange={(e)=>setPassword(e.target.value)} 
                  placeholder='8 xonadan iborat bo’lish kerak'
                />
              </Modal>
            </div>
            <div>
              <p>{Parent?.full_name}</p>
              <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
                <span>
                  {/* <img src={user} alt="" /> */}
                  <UserPic/>
                </span>
              </Dropdown>
              
            </div>
          </div>
          <div className="farzandlar">
            {Childs?.map((item, index)=>{
              return(
                <div 
                  className="farzand" key={index} 
                  id={num === item.child.id ?"f_active":""}  
                >
                  <div className="f_info">
                    <div>
                      <img src={BASE_URL+item.child.image} alt="" />
                      <p>{item.child.full_name}</p>
                    </div>
                    <div>
                      <p>{item.child.school}</p>
                      <p>{item.child.status}</p>
                    </div>
                  </div>
                  <div className="f_status">
                    <Progress 
                      percent={50} 
                      showInfo={false} 
                      strokeColor={"#4D0375"}
                      strokeWidth={"12px"}
                    />
                    <span>
                      <Arrow_parent/>
                    </span>
                    {/* <DeleteOutlined/> */}
                  </div>
                  <div className='f_check' onClick={()=>handleFarzand(item.child.id)}></div>
                  <Dropdown 
                    trigger={["click"]} 
                    placement="bottomRight"
                    overlay={
                      <Menu
                        className='dot3'
                        items={[
                          {
                            label: <span onClick={()=>deleteChild(item.id)}>O'chirish</span>,
                            key: "0"
                          },
                        ]}
                      />
                    } 
                  >
                    <MoreOutlined style={{fontSize: "18px"}}/>
                  </Dropdown>
                </div>
              )
            })}
          </div>
        </div>
          <div className="f_results">
            {ChildCourses.length > 0 ? 
              <div className="table__div">
                <table>
                  <thead>
                    <tr className='f_first'>
                      <td>Kurslar</td>
                      <td>Ustoz</td>
                      <td>Ta'rifi</td>
                    </tr>
                  </thead>
                  <tbody>
                    {ChildCourses?.map((item,index)=>{
                      return(
                        <tr key={index}>
                            <td>{item.course.title}</td>
                            <td>{item.course.teacher.full_name}</td>
                            <td>{item.course.short_description}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              : ""
              }
            {ChildQuezs.length > 0 ? 
              <div className="table__div">
                <table>
                  <thead>
                    <tr className='f_first'>
                      <td>Testlar</td>
                      <td>To'plangan Ball</td>
                      <td>Natija</td>
                    </tr>
                  </thead>
                  <tbody>
                    {ChildQuezs?.map((item,index)=>{
                      return(
                        <tr key={index}>
                            <td>{item.quiz.title}</td>
                            <td>{item.mark} ball</td>
                            <td>{item.is_passed?"o'tdi":"o'ta olmadi"}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              : ""
            }
          </div>
        <Footer/>
      </div>
    </React.Fragment>
  )
}

export default Parents;