import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../constants/base_url';
import { Box, Input, InputLabel, InputLabelError, InputLabelSuccess, RegisterTitle, Wrapper } from '../RegisterPage/Pages/style'
import { ButtonWrapper, Nextbutton,Image1, Image2, Image3, Image4, Image5, Image6, } from '../RegisterPage/style';
import { Container, ContainerBlock, ContentWrapper } from './style'

//images
import Pic1 from '../../assets/images/register/pic1.png';
import Pic2 from '../../assets/images/register/pic2.png';
import Pic3 from '../../assets/images/register/pic3.png';
import Pic4 from '../../assets/images/register/pic4.png';
import Pic5 from '../../assets/images/register/pic5.png';
import Pic6 from '../../assets/images/register/pic6.png';
import Swal from 'sweetalert2';

const ParentOthers = () => {
  const [login, setlogin] = useState('');
  const [parol, setparol] = useState('');
  const [parolconfirm, setparolconfirm] = useState('');
  const [familya,setfamilya] = useState('');

  const [usernameStatus, setusernameStatus] = useState(false);
  const [passwordStatus, setpasswordStatus] = useState(false);

  const [usernameError, setusernameError] = useState('');
  const [passwordError, setpasswordError] = useState('');

  const phone = localStorage.getItem('phone_parent') ? localStorage.getItem('phone_parent') : ""
  const navigate = useNavigate()




  async function check_username(){
    try {
      const res = await axios.get(BASE_URL + `/api/v1/parent/check-username/?username=${login}`);
      if(res){
        // console.log('res', res)
        setusernameStatus(res.data.success);
        if(login?.length>2){
          if(res.data?.success){
            setusernameError(login + ' loginidan foydalanish mumkin')
          }else{
            setusernameError("Bu login allaqachon ro'yhatdan o'tgan")
          }
        }
      }
      
    } catch (err) {
      
    }
  }

  useEffect(() => {
    check_username()
  }, [login]);


  useEffect(() => {
      if(parol===parolconfirm){
        setpasswordError('Parollar mos tushdi , davom etishingiz mumkin')
        setpasswordStatus(true)
      }else{
        setpasswordStatus(false)
        setpasswordError('Parollar mos kelmadi');
      }
  }, [parol,parolconfirm]);


  async function onSubmit(e){
    e.preventDefault();
    let data = new FormData();
    data.append('full_name',familya);
    data.append('username',login);
    data.append('password',parol);
    data.append('phone',phone);
    
    try {
      const res = await axios.post(BASE_URL + "/api/v1/parent/register/",data);
      if(res){
        if(res.data?.success){
          localStorage.setItem("parent_token",JSON.stringify(res.data?.token))
          localStorage.setItem("user_parent",JSON.stringify(res.data?.user))
          localStorage.removeItem('phone_parent');
          Swal.fire({
            icon: 'success',
            title: 'Tabriklaymiz',
            text: "Siz muvaffaqiyatli ro'yhatga olindigiz",
            confirmButtonText:'Okay',
          }).then((result) => {
            if (result.isConfirmed) {
              navigate('/dashboard_parents');
            }
          })
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: "Ro'yhatga olinishda xatolik ro'y berdi",
            confirmButtonText:'Qaytadan to`ldirish'
          })
        }
        

      }
    } catch (err) {
      
    }
  }



  return (
    <Container>
      <ContentWrapper>
        <form onSubmit={onSubmit}>
        <ContainerBlock>
          <RegisterTitle>Ro’yhatdan o’tish</RegisterTitle>
          <Wrapper>
              <Box>
                <InputLabel> Familya, ismingiz </InputLabel>
                <Input required={true} value={familya} onChange={(e)=>setfamilya(e.target.value)} />
              </Box>

            <Box>
              <InputLabel> Login * </InputLabel>
              <Input required value={login} onChange={(e)=>setlogin(e.target.value)} />
              {
                login?.length < 3 ? null : usernameError?.includes('allaqachon')  ? <InputLabelError >{usernameError}</InputLabelError> : <InputLabelSuccess >{usernameError}</InputLabelSuccess>
              }
            </Box>
            <Box>
              <InputLabel> Yangi parol kiriting *</InputLabel>
              <Input required type={'password'} placeholder="Parol 5ta belgidan ko'p bo'lishi kerak" value={parol} onChange={(e)=>setparol(e.target.value)}  />
            </Box>
            <Box>
              <InputLabel> Parolni qayta kiriting * </InputLabel>
              <Input required type={'password'} placeholder="Parol 5ta belgidan ko'p bo'lishi kerak" value={parolconfirm} onChange={(e)=>setparolconfirm(e.target.value)}  />
              {parolconfirm?.length > 0  ? (!passwordStatus ? <InputLabelError >{passwordError}</InputLabelError> : <InputLabelSuccess >{passwordError}</InputLabelSuccess>) : null}
            </Box>
          </Wrapper>
          <ButtonWrapper style={{marginTop:40}}>
            <Nextbutton style={{opacity:passwordStatus === true && usernameStatus ===true ? 1 : 0.2}} disabled={!passwordStatus && !usernameStatus} type={passwordStatus === true && usernameStatus ===true ? 'submit' : "reset"}>Tasdiqlash</Nextbutton>
           </ButtonWrapper>
          </ContainerBlock>
        </form>
      </ContentWrapper>
      <Image1 src={Pic1} />
      <Image2 src={Pic2} />
      <Image3 src={Pic3} />
      <Image4 src={Pic4} />
      <Image5 src={Pic5} />
      <Image6 src={Pic6} />
    </Container>
  )
}

export default ParentOthers