import { Steps } from 'antd';
import React, { useState, useEffect } from 'react';
import { ButtonWrapper, Container, ContentWrapper, Image1, Image2, Image3, Image4, Image5, Image6, Nextbutton, StepWrapper } from './style';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
//images
import Pic1 from '../../assets/images/register/pic1.png';
import Pic2 from '../../assets/images/register/pic2.png';
import Pic3 from '../../assets/images/register/pic3.png';
import Pic4 from '../../assets/images/register/pic4.png';
import Pic5 from '../../assets/images/register/pic5.png';
import Pic6 from '../../assets/images/register/pic6.png';

//pages
import Part1 from './Pages/Part1';
import Part2 from './Pages/Part2';
import Part3 from './Pages/Part3';
import Part4 from './Pages/Part4';
import Part5 from './Pages/Part5';
import Part6 from './Pages/Part6';

import axios from 'axios';
import { BASE_API_URL } from '../../constants/base_url';
import { Wrapper, Wrapper3 } from './Pages/style';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
const { Step } = Steps;


const App = () => {
  const [current, setCurrent] = useState(
  localStorage.getItem('register_active_step') !==null ? 
  localStorage.getItem('register_active_step')*1 : 0);
  const [loginerror, setloginerror] = useState('');
  const [login, setlogin] = useState('');
  const [parol, setparol] = useState('');
  const [parolconfirm, setparolconfirm] = useState('');
  const [status, setstatus] = useState(localStorage.getItem('regis_status') ? JSON.parse(localStorage.getItem('regis_status')) : false);
  const [loading, setloading] = useState(false);
  let registertoken = JSON.parse(localStorage.getItem('registerToken'));
  const Phonenum = localStorage.getItem('phone_register')
  const user_data = useSelector(state=>state?.register);
  const [passwordtext, setpasswordtext] = useState('');
  const [passwordMatch, setpasswordMatch] = useState(false);

  
  // console.log(user_data)
  

  useEffect(() => {
    localStorage.setItem('register_active_step',current);
    window.scrollTo({top:0,behavior:'auto'});
  }, [current]);


  async function check_username(){
    let formdata = new FormData();
    formdata.append('username',login)
    try {
      const res = await axios.post(BASE_API_URL + '/check-username/',formdata);
      if(res){
        if(login?.length > 2){
          if(current===0){
      

            if(parol===parolconfirm && parol?.length>2){
              localStorage.setItem('regis_status',res?.data?.status)
              setstatus(res?.data?.status);
            }else{
              localStorage.setItem('regis_status',false);
              setstatus(false)
              setpasswordtext('Parollar mos kelmadi');
              setpasswordMatch(false)
            }
          }else{
            
          }
        
        }
        if(res?.data?.status){
          setloginerror(login + ' loginidan foydalanish mumkin')
        }else{
          setloginerror(login + " allaqachon ro'yhatga olingan")
        }
      }
    } catch (err) {
      
    }
  }

  useEffect(() => {
    if(current===0){
      if(login?.length > 2){
        check_username();
      }else{
        localStorage.setItem('regis_status',false);
        setstatus(false)
      }
    }else{
      
    }
  }, [login]);

  
  useEffect(() => {
    if(current===0){
      

      if(parol===parolconfirm && parol?.length>2){
        setpasswordtext('Parollar mos tushdi , davom etishingiz mumkin')
        setpasswordMatch(true)
        localStorage.setItem('regis_status',true);
        setstatus(true)
      }else{
        localStorage.setItem('regis_status',false);
        setstatus(false)
        setpasswordtext('Parollar mos kelmadi');
        setpasswordMatch(false)
      }
    }else{
      
    }
  }, [parol,parolconfirm]);


  const register_user =  () => {
    if(login?.length>2 && parol?.length>2){
      setloading(true)
      let formdata = new FormData();
      formdata.append('username',login)
      formdata.append('password',parol)
      formdata.append('phone',Phonenum)
      localStorage.setItem('regis_status',false);
      setstatus(false)

      try {
        axios.post(BASE_API_URL + `/register/v1/student/by/website/f/`,formdata).then((res)=>{
          if(res){
            localStorage.setItem('regis_status',true)
            setstatus(true);
            setloading(false)
            setCurrent(current+1);
            localStorage.setItem('registerToken',JSON.stringify(res?.data?.token));
          }
        })
      } catch (err) {
        
      }
    }else{
      Swal.fire({
        title:"Eslatma!",
        text:"Login va parol 3 ta belgidan ko'p bo'lishi kerak",
        icon:'info'
      })
    }
  }

  const onChange = (value) => {
      setCurrent(value);
  };

    console.log(current)

  const next_handler = () => {
    if(current === 0){
      register_user()
    }else if(current === 6){
      let formdata = new FormData();
      formdata.append("name", user_data?.ism);
      formdata.append("last_name", user_data?.familya);
      formdata.append("fathers_name", user_data?.sharif);
      formdata.append("birth_date", user_data?.yosh);
      formdata.append("language", user_data?.talimtili);
      formdata.append("address", user_data?.mahalla);
      formdata.append("city", user_data?.shaxar);
      formdata.append("subject_one", user_data?.fan1);
      formdata.append("subject_two", user_data?.fan2);
      formdata.append("subject_three", user_data?.fan3);
      formdata.append("subject_four", user_data?.fan4);
      formdata.append("subject_five", user_data?.fan5);
      formdata.append("status", user_data?.type);
      try {
        axios.post(BASE_API_URL+'/student/v1/update/',formdata,{
          headers:{
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${registertoken?.access}`
          }
        }).then((res)=>{
          setCurrent(current+1);
          // console.log(res)
          // console.log(formdata)
          // localStorage.removeItem('register_active_step');
          // localStorage.removeItem('registerToken');
          // localStorage.removeItem('phone_register');
          // window.location.href = '/';
        })
      } catch (err) {
        
      }
    }else if(current === 7 || current >= 6){
      localStorage.removeItem('register_active_step');
      localStorage.removeItem('registerToken');
      localStorage.removeItem('phone_register');
      window.location.href = '/';
    }else{
      setCurrent(current+1);
    }
  }

  return (
    <Container >
        
        <StepWrapper>
            <Steps 
                onChange={onChange} 
                current={current} 
                responsive={false}
            >
                <Step disabled={!status || !!registertoken}  />
                <Step disabled={!status} />
                <Step disabled={!status} />
                <Step disabled={!status} />
                <Step disabled={!status} />
                <Step disabled={!status} />
            </Steps>
        </StepWrapper>
        <ContentWrapper className='dostonbek_select'>
            {
                current === 0 ? <Part1 passwordMatch={passwordMatch} passwordtext={passwordtext} loginerror={loginerror} login={login} setlogin={setlogin} parol={parol} setparol={setparol} parolconfirm={parolconfirm} setparolconfirm={setparolconfirm} /> :
                current === 1 ? <Part2 setCurrent={setCurrent} current={current} /> : 
                current === 2 ? <Part3 setCurrent={setCurrent} current={current} /> : 
                current === 3 ? <Part4 setCurrent={setCurrent} current={current} /> : 
                current === 4 ? <Part5 setCurrent={setCurrent} current={current} /> : 
                current === 5 ? <Part6 next_handler={next_handler} /> : 
                <Wrapper3>
                  <div><CheckCircleIcon className='iconka' /></div>
                  <h1>Arizangiz qabul qilindi! Operator so'rovingizni tasdiqlaganidan so'ng  javobini SMS tarzida yuboramiz. Iltimos, kuting!</h1>
                </Wrapper3>
            }
        </ContentWrapper>
        {current === 5 || current === 4 || current === 3 || current === 2  || current === 1 ? null : <ButtonWrapper>
            <Nextbutton 
              style={{opacity:!status ? 0.1 : 1,cursor:!status ? 'not-allowed' : "pointer"}}
              disabled={!status} 
              onClick={next_handler}
            >
                {loading ? 'Ro`yhatga olinmoqda...' : "Keyingisi"}
            </Nextbutton>
        </ButtonWrapper>}
        <Image1 src={Pic1} />
        <Image2 src={Pic2} />
        <Image3 src={Pic3} />
        <Image4 src={Pic4} />
        <Image5 src={Pic5} />
        <Image6 src={Pic6} />
    </Container>
  );
};
export default App;