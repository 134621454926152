import styled from 'styled-components';
import footer from "../../assets/images/Kabinet/footer.webp"

export const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 100%;
  /* background: linear-gradient(${p => p.lg}), url(${footer}); */
  /* background: url(${p => p.lg}); */
  /* clip-path: polygon(100% 0%, 0% 0% , 0% 99.33%, 1% 99.26%, 2% 99.05%, 3% 98.71%, 4% 98.23%, 5% 97.62%, 6% 96.89%, 7% 96.04%, 8% 95.09%, 9% 94.04%, 10% 92.90%, 11% 91.68%, 12% 90.41%, 13% 89.08%, 14% 87.71%, 15% 86.32%, 16% 84.92%, 17% 83.53%, 18% 82.15%, 19% 80.81%, 20% 79.51%, 21% 78.27%, 22% 77.09%, 23% 76.01%, 24% 75.01%, 25% 74.12%, 26% 73.34%, 27% 72.68%, 28% 72.14%, 29% 71.74%, 30% 71.47%, 31% 71.35%, 32% 71.36%, 33% 71.51%, 34% 71.80%, 35% 72.22%, 36% 72.78%, 37% 73.46%, 38% 74.26%, 39% 75.17%, 40% 76.18%, 41% 77.29%, 42% 78.47%, 43% 79.72%, 44% 81.03%, 45% 82.38%, 46% 83.76%, 47% 85.16%, 48% 86.56%, 49% 87.94%, 50% 89.30%, 51% 90.63%, 52% 91.89%, 53% 93.09%, 54% 94.22%, 55% 95.25%, 56% 96.19%, 57% 97.02%, 58% 97.73%, 59% 98.32%, 60% 98.78%, 61% 99.10%, 62% 99.28%, 63% 99.33%, 64% 99.24%, 65% 99.01%, 66% 98.64%, 67% 98.13%, 68% 97.50%, 69% 96.75%, 70% 95.89%, 71% 94.92%, 72% 93.85%, 73% 92.70%, 74% 91.47%, 75% 90.19%, 76% 88.85%, 77% 87.48%, 78% 86.09%, 79% 84.69%, 80% 83.30%, 81% 81.92%, 82% 80.59%, 83% 79.29%, 84% 78.06%, 85% 76.91%, 86% 75.83%, 87% 74.85%, 88% 73.98%, 89% 73.22%, 90% 72.58%, 91% 72.07%, 92% 71.69%, 93% 71.44%, 94% 71.34%, 95% 71.37%, 96% 71.55%, 97% 71.86%, 98% 72.31%, 99% 72.88%, 100% 73.59%); */
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 100px;
  position: relative;
  /* transform:rotate(180deg); */
  @media (max-width:1024px){
    margin-top: 70px;
  }
  @media (max-width:768px){
    margin-top: 50px;
  }
  @media (max-width:425px){
    margin-top: 30px;
  }
`
export const LogoFooter = () => (
  <svg version="1.1" id="all__logo" x="0px" y="0px" viewBox="0 0 1080 1080">
    <polygon fill='#FFFFFF'  points="641.93,703.98 641.93,586.26 560.02,586.26 589.82,542.42 641.93,542.42 641.93,461.52 444.4,715.78 
      444.4,730.58 373.29,730.58 396.03,702.74 395.07,423.32 368.14,395.47 462.84,396.19 432.8,426.28 432.8,543.28 514.18,543.28 
      487.48,583.06 433.66,583.06 433.66,667.5 638.25,395.57 707.97,395.57 683.81,418.16 682.52,702.93 709.88,730.58 619.62,730.58 
      "/>
    <g>
      <path fill='#FFFFFF' d="M128.36,408.01c-0.08,176.92-0.48,354.23-0.48,357.27l-0.04,16.06h0.24
        C128.24,684.29,128.44,534.78,128.36,408.01z M160.28,228.17c-0.12-0.04-0.2-0.04-0.32-0.04c0.16,22.29,0.24,50.06,0.28,81.47
        c0.04,22.21,0.04,46.23,0.08,71.4c0-24.09,0.04-48.02,0.04-71.36C160.36,281.31,160.32,253.86,160.28,228.17z M960.12,756.85
        c-0.32-29.97-0.48-94.73-0.52-172.76c-0.04,68.8-0.04,135.72,0.04,189.58l0.64,0.04L960.12,756.85z M927.88,223.7
        c-0.04,37.84-0.12,81.03-0.2,126.22c-0.04,78.75-0.08,163.49-0.04,236.49c0.04-82.39,0.16-167.65,0.24-236.57
        c0.08-56.74,0.16-102.32,0.24-126.18C928.04,223.66,927.96,223.7,927.88,223.7z"/>
    </g>
    <g>
      <path fill='#FFFFFF' d="M128.32,194.06l-0.53-0.06l0.12,17.11c0.18,22.64,0.3,53.74,0.38,90.18c0.03,23.76,0.06,49.81,0.12,77.29
        c0-26.16,0.03-52.08,0.03-77.26C128.44,263.05,128.41,226.66,128.32,194.06z M160.36,411.99c-0.09,145.48-0.33,290.29-0.44,337.46
        h0.21C160.27,654.57,160.45,524.72,160.36,411.99z M960.17,874.96c-0.24-20.19-0.35-56.13-0.47-101.3l-16.23-0.68
        c-5.29-0.24-10.52-0.38-15.75-0.44c0.09,35.09,0.21,64.73,0.33,85.87c-66.77-1.86-179.27,8.13-285.68,78.39
        c-38.72,25.63-73.42,56.96-103.39,93.34c-50.51-53.47-131.18-117.55-244.83-147.23c-1.77-0.47-3.52-0.92-5.29-1.33v33.02
        c114.54,30.65,193.16,98.78,239.04,150.75L540.8,1080l11.79-15.55c30.33-39.96,66.45-73.92,107.38-100.97
        c108.39-71.59,223.96-76.11,283.2-72.39l17.14,1.03L960.17,874.96z M159.92,866.92c0.03-17.32,0.09-47.35,0.18-85.45
        c-5.38-0.12-10.76-0.18-16.17-0.18l-15.81,0.06c-0.12,60.53-0.21,100.65-0.21,102.03l-0.03,16.82l16.82-0.83
        c22.4-1.09,44.66-0.62,66.71,1.42v-32.07c-14.31-1.27-28.73-1.89-43.18-1.89C165.47,866.83,162.67,866.83,159.92,866.92z
        M959.93,188.35c-0.06,42.71-0.12,95.59-0.21,152.05c-0.09,80.55-0.12,168.3-0.09,243.68c0.06-85.54,0.18-173.86,0.24-243.71
        c0.12-78.06,0.24-132.92,0.3-134.64l0.03-17.44L959.93,188.35z M927.69,586.41c-0.06,54.48-0.06,107.71,0,154.15h0.35
        C927.8,706.83,927.69,651.29,927.69,586.41z M160.36,411.99c-0.09,145.48-0.33,290.29-0.44,337.46h0.21
        C160.27,654.57,160.45,524.72,160.36,411.99z"/>
      <path fill='#FFFFFF' d="M959.93,188.35c-10.55,0.8-21.19,1.8-31.92,3.01c-146.67,16.67-274.71,68.55-382.87,133.51
        c-143.68-86.55-274.89-119.98-384.88-128.75c-5.14-0.41-10.29-0.77-15.43-1.06l-16.52-1c0.09,32.6,0.12,68.99,0.12,107.27
        c0,25.18-0.03,51.11-0.03,77.26c0,9.64,0.03,19.48,0,29.41c0.06,126.77-0.12,276.28-0.3,373.35l15.81-0.06
        c5.41,0,10.79,0.06,16.17,0.18c17.17,0.35,34.32,1.33,51.31,2.96c25.45,2.39,50.63,6.24,75.4,11.47c0.68,0.15,1.36,0.3,2.04,0.44
        c60.48,12.8,117.61,25.27,170.7,68.75c26.48,21.73,48.8,48.48,66.45,79.48l12.95,22.88l14.34-22.08
        c20.34-31.33,45.14-58.91,73.78-81.96c51.9-41.74,101.98-57.02,138.48-68.19c52.11-15.9,106.59-23.53,162.18-22.67
        c5.23,0.06,10.46,0.21,15.75,0.44l16.23,0.68c-0.09-53.85-0.09-120.77-0.06-189.59c-0.03-75.37,0-163.13,0.09-243.68
        C959.82,283.94,959.87,231.06,959.93,188.35z M928.04,740.56h-0.35c-58.79-0.8-116.34,7.27-171.44,24.09
        c-36.86,11.26-92.55,28.23-149.21,73.87c-24.92,20.01-47.17,43.18-66.39,69.05c-17.14-25.57-37.57-48.12-60.86-67.16
        c-59.65-48.95-121.22-61.98-186.39-75.82c-43.39-9.16-88.17-14.31-133.28-15.13h-0.21c0.12-47.17,0.35-191.98,0.44-337.46
        c0.03-10.49,0.03-20.84,0-30.98c-0.03-25.18-0.03-49.18-0.09-71.41c-0.03-31.39-0.12-59.18-0.27-81.46c0.12,0,0.21,0,0.33,0.03
        c76.02,9.58,174.72,31.6,279.74,83.03c38.57,18.89,72.8,39.22,102.86,59.41c56.4-36.53,132.15-77.53,226.56-108.77
        c57.9-19.15,111.55-30.89,158.43-38.13c0.09,0,0.15-0.06,0.24-0.06c-0.09,23.85-0.15,69.46-0.24,126.18
        c-0.09,68.93-0.21,154.17-0.24,236.58C927.69,651.29,927.8,706.83,928.04,740.56z"/>
    </g>
    <path fill='#FFFFFF' d="M288.85,796.33v171.11h-77.44V784.42c25.45,2.39,50.63,6.24,75.4,11.47
      C287.49,796.04,288.17,796.18,288.85,796.33z"/>
    <path fill='#FFFFFF' d="M543.41,224.73l185.14-50.32v30.34c-67.84,22.77-130.1,52.91-186.61,86.86
      c-63.96-38.54-125.33-65.89-183.19-84.88l0.82-52.44L185,105.65L543.41,0l359.71,106.5L543.41,207.96V224.73z"/>
  </svg>
)