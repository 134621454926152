import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useAxios from '../../../../api/useAxios'
import { baseURLMedia } from '../../../../context/BaseURL'
import { Blocks, Container, Word, Wrapper } from './style'
import {LoadingContainer} from '../style'

const Manba = () => {
  const {id} = useParams()
  const api = useAxios();
  const [AttachData, setAttachData] = useState([]);
  const Base_style = localStorage.getItem('maktab') !== null ? localStorage.getItem('maktab') : '0';

  
  const GetAttachments = async () =>{
    try {
        const res =await api.get(`/course/attachments/?course=${id}`);
        if(res.data.success){
          // console.log("attachs",res);
          setAttachData(res.data.data);
        }    
    } catch (err) {
      
    }
  }

  useEffect(() => {
    GetAttachments()
  }, []);


  return (
    <React.Fragment>
      {
        AttachData?.length === 0 ? 
        <LoadingContainer>Loading...</LoadingContainer> :
        <Container>
          <Wrapper>
            {
              AttachData?.length === 0 ? "Loading..." :
              AttachData?.map((item,index)=>(
                <Blocks onClick={()=>window.open(baseURLMedia + item.file)} baseStyle={Base_style} key={index}>
                  <Word>{item.title}</Word>
                </Blocks>
              ))
            }
          </Wrapper>
        </Container>
      }
    </React.Fragment>
  )
}

export default Manba