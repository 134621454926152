import React, { useState } from 'react'
import { ButtonWrapper, Container, ContentWrapper, Image1, Image2, Image3, Image4, Image5, Image6, Nextbutton } from './style'

//images
import Pic1 from '../../assets/images/register/pic1.png';
import Pic2 from '../../assets/images/register/pic2.png';
import Pic3 from '../../assets/images/register/pic3.png';
import Pic4 from '../../assets/images/register/pic4.png';
import Pic5 from '../../assets/images/register/pic5.png';
import Pic6 from '../../assets/images/register/pic6.png';

import { Box, ContainerT, Input, InputLabel, InputLabelError, InputPhone, RegisterTitle, Wrapper } from './Pages/style';
import axios from 'axios';
import { BASE_API_URL } from '../../constants/base_url';
import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2';

const Phone = () => {
  const [PhoneNumber, setPhoneNumber] = useState('');
  const navigate = useNavigate();
  const [Phoneerror, setPhoneerror] = useState('');



  function Checking(){
    if(PhoneNumber?.includes('_')){
        setPhoneerror("Raqamingizni to'liq kiriting")
    }else{
        setPhoneerror('')
    }
  }

  async function get_code(e){
    e.preventDefault();
    let formdata = new FormData();
    formdata.append('phone',PhoneNumber);
    try {
        const res = await axios.post(BASE_API_URL + "/send/code-for/ahjsbcjhasbchjas/",formdata);
        if(res.data?.success){
            localStorage.setItem('phone_register',PhoneNumber);
            navigate('/phone/confirm',{state:PhoneNumber});
            // navigate('/register',{state:PhoneNumber});
        }else{
            Swal.fire({
                title:"Xatolik",
                text:"Telefon raqamingiz bilan muammo, iltimos tekshirib qaytadan tering",
                icon:'warning'
            })
        }
    } catch (err) {
        
    }
  }



  return (
    <Container>
        
        
        <ContentWrapper>
            <form onSubmit={PhoneNumber?.includes('_') ? Checking : get_code}>
                <ContainerT>
                    <RegisterTitle>Ro’yhatdan o’tish</RegisterTitle>
                        <Wrapper>
                            <Box>
                                <InputLabel> Telefon raqamingiz * </InputLabel>
                                <InputPhone
                                    value={PhoneNumber}
                                    onChange={(e)=>{
                                        setPhoneNumber(e.target.value);
                                        if(Phoneerror !== ''){
                                            setPhoneerror('')
                                        }
                                    }} 
                                    placeholder="+998 _ _ _ _ _ _ _ _ _"
                                    format="+998#########"
                                    mask="_"
                                    required={true}
                                />
                                <InputLabelError>{Phoneerror}</InputLabelError>
                                
                            </Box>
                            <ButtonWrapper style={{marginTop:10}}>
                                <Nextbutton onClick={Checking}  type={PhoneNumber?.includes('_') ? 'reset' : 'submit'}>
                                    Tasdiqlash
                                </Nextbutton>
                            </ButtonWrapper>
                        </Wrapper>
                </ContainerT> 
            </form>
        </ContentWrapper>
       
        <Image1 src={Pic1} />
        <Image2 src={Pic2} />
        <Image3 src={Pic3} />
        <Image4 src={Pic4} />
        <Image5 src={Pic5} />
        <Image6 src={Pic6} />
    </Container>
  )
}

export default Phone