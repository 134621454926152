import styled from 'styled-components'
import { Radio } from 'antd';
import NumberFormat from 'react-number-format';


export const Container = styled.div`
    width:100%;
    display:flex;
    justify-content:flex-start;
    /* border:1px solid red; */
    align-items: center;
    flex-direction: column;
    
`
export const ContainerT = styled.div`
    width:100%;
    display:flex;
    justify-content:flex-start;
    /* border:1px solid red; */
    align-items: center;
    flex-direction: column;
    margin-top: 70px;
    
`

export const Wrapper = styled.div`
    width: 100%;
    max-width: 420px;
    display: flex;
    flex-direction: column;
    gap: 25px;
`
export const Wrapper3 = styled.div`
    width: 100%;
    max-width: 420px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 25px;
    margin: auto;
    h1{
        text-align: center;
        font-size: 24px;
    }
    .iconka{
        font-size: 80px !important;
        color: orange !important;
    }
`
export const Wrapper1 = styled.div`
    width: 100%;
    max-width: 420px;
    h1{
        color: #16AEB5;
        font-size: 15px;
    }
    display: flex;
    height: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
`

export const RegisterTitle = styled.h1`
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 62px;
    text-align: center;
    color: #5E12DA;
`


export const Box = styled.div`
    width: 100%;
    height: auto;
    /* border: 1px solid orange; */
    .css-b62m3t-container{
        margin-top: 20px;

    }
    .css-13cymwt-control{
        height: 55px ;

    }
    .css-t3ipsp-control{
        height: 55px ;
        
    }
    .css-1u9des2-indicatorSeparator{
        display: none ;

    }
    .css-1s2u09g-control{
        height: 55px ;
    }
    .css-1pahdxg-control{
        height: 55px ;
    }
    .css-1okebmr-indicatorSeparator{
        display: none ;
    }
`
export const BoxCode = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    /* border: 1px solid orange; */
    .css-b62m3t-container{
        margin-top: 20px;

    }
    .ReactInputVerificationCode__item{
        width: 60px !important;
        height: 60px !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        color: blue !important;
        font-weight: 600 !important;
    }
    .css-1s2u09g-control{
        height: 55px ;
    }
    .css-1pahdxg-control{
        height: 55px ;
    }
    .css-1okebmr-indicatorSeparator{
        display: none ;
    }
    .css-13cymwt-control{
        height: 55px ;

    }
    .css-t3ipsp-control{
        height: 55px ;
        
    }
    .css-1u9des2-indicatorSeparator{
        display: none ;

    }
`

export const BoxSorovnoma = styled.div`
    width: 100%;
    height: auto;
    margin-bottom: 15px;
    /* border: 1px solid orange; */
    .css-b62m3t-container{
        margin-top: 20px;

    }
    .css-1s2u09g-control{
        height: 55px ;
    }
    .css-1pahdxg-control{
        height: 55px ;
    }
    .css-1okebmr-indicatorSeparator{
        display: none ;
    }
    .css-13cymwt-control{
        height: 55px ;

    }
    .css-t3ipsp-control{
        height: 55px ;
        
    }
    .css-1u9des2-indicatorSeparator{
        display: none ;

    }
`
export const BoxCheck = styled.div`
    width: 100%;
    height: auto;
    /* border: 1px solid orange; */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start ;
    flex-direction: column;
    gap: 10px;
    margin-top: 13px;
`
export const BoxCheckFlex = styled.div`
    width: 100%;
    height: auto;
    /* border: 1px solid orange; */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start ;
    gap: 10px;
    margin-top: 15px;
`

export const InputLabel = styled.div`
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 28px;
    color: #5E12DA;

`
export const InputLabelNew = styled.div`
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 28px;
    color: #5E12DA;
    margin-bottom: 14px;

`
export const InputLabelError = styled.div`
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #ff0000;

`
export const InputLabelSuccess = styled.div`
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #9E22FF;

`
export const Input = styled.input`
    background: rgba(0, 68, 243, 0.1);
    border: 2px solid #D9D9D9;
    border-radius: 5px;
    width: 100%;
    height: 60px;
    padding: 6px;
    box-sizing: border-box;
    margin-top: 10px;
    &:focus{
        outline: none;
        border: 2px solid #2684ff;
    }
    &::placeholder{
        color: #C8C8C8;
    }
`
export const InputPhone = styled(NumberFormat)`
    background: rgba(0, 68, 243, 0.1);
    border: 2px solid #D9D9D9;
    border-radius: 5px;
    width: 100%;
    height: 60px;
    font-size: 19px;
    padding: 6px;
    box-sizing: border-box;
    margin-top: 20px;
    &:focus{
        outline: none;
        border: 2px solid #2684ff;
    }
`
export const InputPhoneCode = styled(NumberFormat)`
    border: 2px solid #5E12DA;
    border-radius: 5px;
    width: 100%;
    height: 60px;
    padding: 6px;
    box-sizing: border-box;
    font-size:40px;
    text-align: center;
    margin-top: 20px;
    color: #5E12DA;
    &:focus{
        outline: none;
        border: 2px solid #2684ff;
        text-align: center;
        font-size:40px;
    }
`
export const InputDate = styled.input`
    background: rgba(0, 68, 243, 0);
    border: 2px solid #D9D9D9;
    border-radius: 5px;
    width: 100%;
    height: 60px;
    padding: 6px;
    box-sizing: border-box;
    margin-top: 10px;
    &:focus{
        outline: none;
        border: 2px solid #2684ff;
    }
`
export const TextArea = styled.textarea`
    border: 2px solid #D9D9D9;
    border-radius: 5px;
    width: 100%;
    min-height: 60px;
    height: 100px;
    padding: 6px;
    box-sizing: border-box;
    margin-top: 10px;
    &:focus{
        outline: none;
        border: 2px solid #2684ff;
    }
`

export const Notes = styled.div`
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 300;
    font-size: 17.6196px;
    line-height: 22px;
    color: #F25471;
`

export const RegisterText = styled.div`
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 40px;
    color: #5E12DA;
`
export const RegisterTextBlack = styled.div`
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 40px;
    color: #000;
`
export const RegisterTextBlack1 = styled.div`
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #001C20;
`
export const RadioMe = styled(Radio)`
   .ant-radio-input{
        width: 50px !important;
        height: 50px !important;
   }
`