import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { BASE_URL } from '../../../constants/base_url';
import { Box, Container, Input, InputDate, InputLabel, RegisterText, RegisterTitle, Wrapper } from './style';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import BaseSelect from "react-select";
import FixRequiredSelect from "./RequiredSelect";
import { ButtonWrapper, Nextbutton } from '../style';

const Part3 = ({setCurrent,current}) => {
  const [Region, setRegion] = useState(null);
  const [district, setdistrict] = useState('');
  const [RegionsOption, setRegionsOption] = useState([]);
  const [CityOption, setCityOption] = useState([]);
  const [mahalla, setMahalla] = useState('');
  const dispatch = useDispatch()



  useEffect(()=>{
      dispatch({type:'REGISTER_3',payload:{viloyat:Region,tuman:district,mahalla:mahalla}})
  },[district,setdistrict,mahalla])

  useEffect(() => {
    axios.get(BASE_URL + '/api/v1/website/region/').then((res) => {
      let newoption = []
      res.data.data?.filter(item => {
        newoption.push({ value: item.id, label: item.name })
      })
      setRegionsOption(newoption)
    })
  }, []);

  useEffect(() => {
    axios.get(BASE_URL + `/api/v1/website/city/?region=${Region}`).then((res) => {
      let newoption = []
      res.data.data?.filter(item => {
        newoption.push({ value: item.id, label: item.name })
      })
      setCityOption(newoption)
    })
  }, [Region, setRegion]);

  function next_handler(e) {
    e.preventDefault()
    setCurrent(current+1)
  }

  return (
    <Container>
      <RegisterTitle>Ro’yhatdan o’tish</RegisterTitle>
      <form onSubmit={(e)=>next_handler(e)} style={{width:'100%',maxWidth:420}}>
      
      <Wrapper>
        <RegisterText>Yashash hududingiz</RegisterText>
        <Box>
          <InputLabel> Viloyat </InputLabel>
          <FixRequiredSelect SelectComponent={BaseSelect}  
            placeholder='Viloyatingiz'   
            isSearchable={false} 
            required
            
            onChange={(e) => {
              setRegion(e.value);
            }}
            options={RegionsOption}
          />
        </Box>
        <Box>
          <InputLabel> Shahar/Tuman </InputLabel>
          <FixRequiredSelect SelectComponent={BaseSelect}  
            placeholder='Tumaningiz'   
            isSearchable={false} 
            required
            options={CityOption}
            onChange={(e) => {
              setdistrict(e.value);
            }}
          />
        </Box>
        <Box>
          <InputLabel> Mahalla/Ko’cha </InputLabel>
          <InputDate 
            required
            placeholder='Mahalla'
            value={mahalla}
            onChange={(e)=>setMahalla(e.target.value)}
          />
        </Box>
      </Wrapper>
        <ButtonWrapper style={{marginTop:30}}>
          <Nextbutton type='submit'>Keyingisi</Nextbutton>
        </ButtonWrapper>
      </form>
    </Container>
  )
}

export default Part3