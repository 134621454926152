import styled from 'styled-components'
import Fullbg from '../../../assets/images/parentsbg.webp'

export const Container = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    height: 100vh;
    background-color: white;
    @media (max-width:480px){
        height: 700px;
    }
`
export const MAxContainer = styled.div`
    max-width: 1920px;
    width: 100%;
    height: 100%;
    background-image: url(${Fullbg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Wrapper = styled.div`
    box-sizing: border-box;
    padding: 15px;
    width: 100%;
    max-width: 1600px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    @media (max-width:1016px){
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`

export const TextWrapper = styled.div`
    width: 50%;
    height: auto;
    @media (max-width:1016px){
        width: 70%;
    }
    @media (max-width:750px){
        width: 90%;
    }
    @media (max-width:480px){
        width: 98%;
    }
`
export const ImgWrapper = styled.div`
    width: 50%;
    height: auto;
    @media (max-width:1016px){
        margin-top: 20px;
        width: 70%;
    }
    @media (max-width:750px){
        width: 90%;
    }
    @media (max-width:480px){
        width: 98%;
    }
    img{
        width: 100%;
    }
`

export const Title = styled.h1`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 90px;
    text-transform: uppercase;

    color: #4D0375;
    @media (max-width:1200px){
        line-height: 60px;
        font-size: 45px;
    }
    @media (max-width:480px){
        line-height: 40px;
        font-size: 35px;
    }

`
export const Text = styled.h1`
    
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 52px;
    color: #414141;
    @media (max-width:1200px){
        line-height: 40px;
        font-size: 25px;
    }
    @media (max-width:480px){
        line-height: 30px;
        font-size: 21px;
    }
`

export const Button = styled.button`
    background: #4D0375;
    border-radius: 12.1687px;
    height: 91px;
    width: 341px;
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 35px;
    border: none;
    text-align: center;
    margin-top: 20px;
    transition: .3s;
    color: #FFFFFF;
    &:hover{
        cursor: pointer;
        background-color: blue;
    }
    @media (max-width:1200px){
        width: 200px;
        height: 60px;
    }
`