import styled from 'styled-components'


export const Container = styled.div`
    width:100%;
    /* border:1px solid red; */
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    /* background-color: rgba(22,22,22,0.2); */
    position: relative;
`


export const StepWrapper = styled.div`
   /* border: 1px solid red; */
   width:100%;
   max-width:600px;
   margin-top: 100px;
   .anticon{
    color:  #5E12DA !important;
   }
    .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
            background-color: #5E12DA;
            height: 3px;
            left: 0px;
    }
    .ant-steps-item-icon{
        margin: 0px;
    }
    .ant-steps-item-finish .ant-steps-item-icon{
        border: 1px solid #5E12DA;
    }
    .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
        background: rgba(0, 24, 244, 0.1);
        color: black;
        border: 1px solid #5E12DA;
    }
    .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon .ant-steps-icon {
        color: black;
    }
    .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
        padding-left: 0px;
        white-space: nowrap;
    }
    .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
        background-color: #f0f0f0;
        height: 3px;
        left: 0px;
    }
    .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
        background-color: #f0f0f0;
        height: 3px;
        left: 0px;
    }
`
export const ContentWrapper = styled.div`
   /* border: 1px solid red; */
   width:100%;
   margin-top: 100px;
   max-width:600px;
   min-height: 300px;
   padding: 20px 0px;
`
export const ContentWrapper1 = styled.div`
   /* border: 1px solid red; */
   width:100%;
   margin-top: 30px;
   max-width:600px;
   min-height: 300px;
   padding: 0px 0px;
    
`

export const ButtonWrapper = styled.div`
   /* border: 1px solid red; */
   margin-top: 10px;
   width:100%;
   max-width:600px;
   display: flex;
   justify-content: center;
   margin-bottom: 50px;

`
export const ButtonWrapper1 = styled.div`
   /* border: 1px solid red; */
   margin-top: 10px;
   width:100%;
   max-width:600px;
   display: flex;
   justify-content: center;
   margin-bottom: 20px;
   a{
    font-size: 17px;
   }
   
`


export const Image1 = styled.img`
    position: absolute;
    z-index:-1;
    right: 30px;
    width: 60px;
    bottom: 20px;
`
export const Image2 = styled.img`
    position: absolute;
    z-index:-1;
    right: 20px;
    width: 60px;
    top: 30%;
`
export const Image3 = styled.img`
    position: absolute;
    z-index:-1;
    right: 30px;
    top: 20px;
    width: 150px;
`
export const Image4 = styled.img`
    position: absolute;
    z-index:-1;
    left: 40px;
    width: 200px;
    bottom: 0px;
`
export const Image5 = styled.img`
    position: absolute;
    z-index:-1;
    left: 40px;
    width: 60px;
    top: 50%;
`
export const Image6 = styled.img`
    position: absolute;
    z-index:-1;
    left: 40px;
    width: 60px;
    top: 20px;
`

export const Nextbutton = styled.button`
    box-shadow: 0px 10px 20px 0px #0018F433;
    background: #5E12DA;
    box-shadow: 0px 10px 20px rgba(0, 24, 244, 0.2);
    border-radius: 10px;
    max-width: 420px;
    height: 70px;
    width: 100%;
    border: none;
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 34px;
    text-align: center;
    transition: .3s;
    color: #FFFFFF;
    &:hover{
        background-color: #5E12FA ;
        cursor: pointer;

    }
`