import React, { useState, useEffect } from 'react';
import axios from 'axios';
import oqituvchi1 from '../../../images/home/homeOqituvchi/chettili.webp'
import { BASE_URL } from '../../../constants/base_url';
const baseUrl = `${BASE_URL}/api/v1/admin`

const Chettili = () => {

  const [info, setInfo] = useState([])
  useEffect(() => {
    axios.get(baseUrl + "/info/")
      .then((res) => {
        const data = res.data.data
        setInfo(data)
        // console.log(data)
      })
  }, [])


  return (
    <React.Fragment>
      <div className="oqituvchi">
        <div className='oqituvchi1' id='chettili'>
          <div className="oqituvchi__inner1">
            <div className="oqituvchi__left">
              <img src={oqituvchi1} alt="" />
              <div className="oqituvchi__left-circle">
                ay
                <span>es!</span>
              </div>
            </div>
            <div className="oqituvchi__right">
              <div className="oqituvchi__right-circle">
                dmission
                <span>ow!</span>
              </div>
              <h2 className='oqituvchi__right-title'>CHET TILINI O‘RGANMOQCHI BO‘LGANLAR
                UCHUN</h2>
              <p className='lastwordfont'>
                Hayot—bu chet tili, uni o‘rganmay turib tushinib bo‘lmaydi
              </p>
              {/* <button className='oqituvchi__right-btn'>Boshlash</button> */}
              <a href='/login/chettili' className='link__animated' style={{ marginTop: "8px" }}>Boshlash</a>
              {/* <button onClick={OpenModal} className='link__animated' style={{marginTop:"8px"}}>Boshlash</button>
              <Modal open={open} setOpen={setOpen}/> */}
            </div>
          </div>

          <ul className="oqituvchi__icon-list">
            <li>
              <a target={"_blank"} href={info?.twitter}><i className="bi bi-twitter"></i></a>
            </li>
            <li>
              <a target={"_blank"} href={info?.facebook}><i className="bi bi-facebook"></i></a>
            </li>
            <li>
              <a target={"_blank"} href={info?.instagram}><i className="bi bi-instagram"></i></a>
            </li>
            <li>
              <a target={"_blank"} href={info?.youtube}><i className="bi bi-youtube"></i></a>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Chettili;