import React from "react";
import {
  BatafsilButton,
  BigText,
  BlurDiv,
  Container,
  ImgContainer,
  ImgHome1,
  ImgHome2,
  ImgHome3,
  LeftWrapper,
  MainBgButton,
  MainBgButton1,
  RightWrapper,
  SmallText,
  Wrapper,
} from "./style";
import Home1 from "../../../images/home/1.webp";
import Home2 from "../../../images/home/2.webp";
import Home3 from "../../../images/home/3.webp";

import Atropos from 'atropos/react';

const Home = () => {
  return (
    <div>
      <Container>
        <BlurDiv />
        <Wrapper>
          <LeftWrapper>
            <BigText>
              <MainBgButton>Hokim</MainBgButton><MainBgButton1>Akademiyasi</MainBgButton1> —O‘zbekistonda yagona zamon va makon tanlamas birinchi online 
             platforma!
            </BigText>
            <SmallText>

            <p>Ushbu platforma Prezident maktabiga kirish istagidagi o‘quvchilar, abiturientlar, chet tilini o‘rganish ishtiyoqidagi yoshlar va o‘z malakasini oshirish maqsadida bo‘lgan o‘qituvchilar uchun mo‘ljallangan.</p>

            Hokim Akademiyasi sizga uydan chiqmagan holda yuksak marralarga erishishingizga asos bo‘ladi!
            </SmallText>
            <BatafsilButton href="#maktab">Batafsil</BatafsilButton>
          </LeftWrapper>
          
          <RightWrapper className="sdfsdfsdfsdfsdfsdf">
            <ImgContainer>
              {/* <Atropos shadow={false} className="my__atropos1"></Atropos>
              <Atropos shadow={false} className="my__atropos2"></Atropos>
              <Atropos shadow={false} className="my__atropos3"></Atropos> */}
              <ImgHome1 src={Home2} />
              <ImgHome2 src={Home3} />
              <ImgHome3 src={Home1} />
            </ImgContainer>
          </RightWrapper>
        </Wrapper>
      </Container>
    </div>
  );
};

export default Home;
