import styled from "styled-components";
import BgJust from '../../../assets/images/Ariza/justbg.webp';
import Fullbg from '../../../assets/images/Ariza/fullbg.webp';
import Buttons from '@mui/material/Button'

export const Container = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-image: url(${BgJust});
    background-size: cover;
    background-repeat: no-repeat;
    
`

export const MAxContainer = styled.div`
    max-width: 1920px;
    width: 100%;
    height: 100%;
    background-image: url(${Fullbg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    align-items: center;
`

export const  InnerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 900px;
    padding: 50px;
    box-sizing: border-box;
    @media (max-width:1050px){
        flex-direction: column;
    }
    @media (max-width:650px){
        padding: 10px;
    }
`
export const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    width: 50%;
    height: auto;
    min-height: 450px;
    padding: 20px;
    box-sizing: border-box;
    @media (max-width:1050px){
        width: 90%;
    }
    @media (max-width:650px){
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
    }
`
export const RightContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 80%;
    padding: 20px;
    box-sizing: border-box;
    @media (max-width:1050px){
        width: 90%;
        height: 50%;
        justify-content: flex-end

    }
    @media (max-width:650px){
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
    }
`

export const RightImg = styled.img`
    width: 60%;
    @media (max-width:1500px){
        width: 90%;
    }
    @media (max-width:1050px){
        width: 450px;
        
    }
    @media (max-width:650px){
        width: 300px;
    }
`

export const Title = styled.div`
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 500;
    font-size: 47px;
    line-height: 55px;
    color: #000000;
    @media (max-width:650px){
        font-size: 35px;
        line-height: 45px;
    }
`

export const Text = styled.div`
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 45px;
    color: #000000;
    margin-top: 20px;
    margin-bottom: 20px;
    @media (max-width:1500px){
        font-size: 25px;
        line-height: 35px;
    }
    @media (max-width:650px){
        font-size: 20px;
        line-height: 30px;
    }
`

export const Button = styled(Buttons)`
    font-family: 'Lexend' !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 18px !important;
    line-height: 35px !important;
    color: #FFFFFF !important;
    height: 62px !important;
    width: 237px !important;
    display: flex !important;
    border-radius: 5px !important;
    padding: 10px 30px 10px 30px !important;
    background: #E2A727 !important;
    box-shadow: 0px 15px 35px 0px #FFC80333 !important;
    text-transform: capitalize !important;
    @media (max-width:650px){
        font-size: 16px !important;
        line-height: 25px !important;
        color: #FFFFFF !important;
        height: 52px !important;
        width: 187px !important;
    }
`

export const ModalContainer = styled.div`
    width: 100%;
`


export const SelectContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @media (max-width:500px){
        flex-direction: column;
    }
`

export const EachBox = styled.div`
    width: 48%;
    height: 100px;
    margin-top: 10px;
    @media (max-width:500px){
        width: 98%;
    }

    .css-319lph-ValueContainer{
        height: 54px !important;
    }
    .css-1s2u09g-control{
        border: 1px solid #DADADA;
        border-radius: 5px;
        height: 54px !important;
        
    }
    
    .css-1okebmr-indicatorSeparator{
        display: none;
    }
`
export const EachBox1 = styled.div`
    width: 100%;
    height: 200px;
    margin-top: 10px;
    @media (max-width:500px){
        width: 98%;
    }
`
export const CourseInput = styled.input`
    width: 100%;
    height: 54px;
    border: 1px solid #DADADA;
    border-radius: 5px;
    outline: none;
    padding-left: 8px;
    box-sizing: border-box;
    font-size: 16px;
    text-align: start;
    &:focus{
        outline: 2px solid #2684FF;
    }
`
export const CourseTextArea = styled.textarea`
    width: 100%;
    height: 154px;
    border: 1px solid #DADADA;
    border-radius: 5px;
    outline: none;
    padding-left: 8px;
    padding-top: 5px;
    box-sizing: border-box;
    font-size: 16px;
    text-align: start;
    &:focus{
        outline: 2px solid #2684FF;
    }
`

export const SelectTexts = styled.div`
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    padding: 10px 0px;
    color: #ffffff;
    
`

