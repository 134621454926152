import React, { useContext } from 'react';
import Image from "../../../assets/images/Login/abiturient_img.webp"
import AuthContext from '../../../context/AuthContext';

const Abituriyent = () => {
  const { loginUser } = useContext(AuthContext)
  return (
    <React.Fragment>
      <div className="loginpage__container abiturient">
        <div className="login__page">
          <div className='login__form'>
            <h1 className='login__title'>ABITURIYENTLAR UCHUN</h1>
            <form action="" onSubmit={(e) => loginUser(e)}>
              <h1>Kirish</h1>
              <p>Login</p>
              <input type="text" name='username' required placeholder="Login" />
              <p>Parol</p>
              <input type="password" name='password' required placeholder="Parol" />
              <button type='submit'>Kirish</button>
            </form>
          </div>
          <div className="loginpage__img">
            <img src={Image} alt="" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Abituriyent;