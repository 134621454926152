export const Arrow_parent = () => (
  <svg width="19" height="14" viewBox="0 0 24 19" fill="none">
    <path d="M1.49274 8.59539H21.2703L14.1072 1.43224C13.9931 1.31414 13.93 1.15598 13.9314 0.991802C13.9328 0.827628 13.9987 0.670583 14.1148 0.554489C14.2309 0.438396 14.3879 0.372544 14.5521 0.371117C14.7163 0.369691 14.8744 0.432803 14.9925 0.546861L23.2239 8.77948C23.3413 8.8969 23.4072 9.05613 23.4072 9.22217C23.4072 9.3882 23.3413 9.54743 23.2239 9.66486L14.9925 17.8975C14.9345 17.9561 14.8655 18.0026 14.7894 18.0344C14.7133 18.0661 14.6317 18.0825 14.5492 18.0825C14.4668 18.0825 14.3851 18.0661 14.309 18.0344C14.2329 18.0026 14.1639 17.9561 14.1059 17.8975C13.9885 17.7801 13.9226 17.6208 13.9226 17.4548C13.9226 17.2887 13.9885 17.1295 14.1059 17.0121L21.2691 9.84894H1.49274C1.32651 9.84894 1.16709 9.78291 1.04955 9.66536C0.932003 9.54782 0.865969 9.3884 0.865969 9.22217C0.865969 9.05593 0.932003 8.89651 1.04955 8.77897C1.16709 8.66143 1.32651 8.59539 1.49274 8.59539Z" fill="white"/>
  </svg>
)
export const UserPic = () =>(
  <svg width="50px" height="50px" viewBox="0 0 24 24" fill="none">
    <path d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z" fill="#222222" fillOpacity="0.24"/>
    <circle cx="12" cy="10" r="4" fill="#007D3A"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M18.2209 18.2462C18.2791 18.3426 18.2613 18.466 18.1795 18.5432C16.5674 20.0662 14.3928 21 12 21C9.60728 21 7.43264 20.0663 5.82057 18.5433C5.73877 18.466 5.72101 18.3427 5.77918 18.2463C6.94337 16.318 9.29215 15 12.0001 15C14.7079 15 17.0567 16.3179 18.2209 18.2462Z" fill="#222222"/>
  </svg>
)