import React, { Component } from 'react';
import { FooterContainer } from './style';
import { Link } from "react-router-dom"

// import Images
import DC from "../../../assets/icons/DC.png";
import Youtube from "../../../assets/icons/youtube.svg";
import Facebook from "../../../assets/icons/facebook.svg";
import Telegram from "../../../assets/icons/telegram.svg";
import Instagram from "../../../assets/icons/instagram.svg";
import LogoFooter from "../../../assets/icons/logo_kabinet.svg";

import footer1 from "../../../assets/images/Kabinet/footer1.webp";
import footer2 from "../../../assets/images/Kabinet/footer2.webp";
import footer4 from "../../../assets/images/Kabinet/footer4.webp";
import axios from 'axios';
import { BASE_URL } from '../../../constants/base_url';

class Footer extends Component {
  state = {
    data: []
  }
  scrollTop = () => {
    window.scrollTo(0, 0)
  }

  componentDidMount() {
    let url = `${BASE_URL}/api/v1/admin/info/`
    axios.get(url)
      .then((res) => {
        const data = res.data.data
        // console.log(data)
        this.setState({
          data: data
        })
      })
  }
  render() {
    var info = this.state.data
    const status = localStorage.getItem("maktab")
    const num = status === "1" ? "1"
      : status === "2" ? "2"
        : status === "3" ? "2"
          : "4"
    const lg =
      status === "1" ? footer1
        : status === "2" ? footer2
          : status === "3" ? footer2
            : footer4
    // const bg = 
    //   status === "1" ? img1 
    // : status === "2" ? img2
    // : status === "3" ? img2
    // : img4
    return (
      <FooterContainer lg={lg}>
        <div className="footer__bg">
          <img src={lg} alt="" />
        </div>
        <footer className='footer footer__kabinet' id={`footer__kabinet${num}`}>
          <div className='footer__image' id={`footer__image${num}`}>
            {/* <img src={bg} alt=""/> */}
          </div>
          <div className="footer__left">
            <img src={LogoFooter} alt="" />
            <p>O'qituvchilar va talabalar uchun onlayn platforma</p>
          </div>
          <div className="footer__center">
            <Link onClick={this.scrollTop} to="/">Bosh sahifa</Link>
            <Link onClick={this.scrollTop} to="/kabinet/kurslar">Kurslar</Link>
            <Link onClick={this.scrollTop} to="/kabinet/saved">Mening kurslarim</Link>
            <Link onClick={this.scrollTop} to="/kabinet/taxrirlash">Tahrirlash</Link>
          </div>
          <div className="footer__right">
            <div className="dc__logo">
              <img src={DC} alt="" />
              <div>
                <p>DIGITAL</p>
                <p>CITY</p>
              </div>
            </div>
            <div className="social__sets">
              <a target={"_blank"} href={info?.facebook}><img src={Facebook} alt="" /></a>
              <a target={"_blank"} href={info?.telegram}><img src={Telegram} alt="" /></a>
              <a target={"_blank"} href={info?.instagram}><img src={Instagram} alt="" /></a>
              <a target={"_blank"} href={info?.yutube}><img src={Youtube} alt="" /></a>
            </div>
          </div>
        </footer>
      </FooterContainer>
    );
  }
}

export default Footer;