import axios from "axios";
import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_API_URL } from '../constants/base_url'

const AuthContext = createContext()

export default AuthContext;

export const AuthProvider = ({ children }) => {
    const notify = () => toast.error('User name yoki parol xato kritildi', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
    });
    const Telegram = () =>  Swal.fire({
        icon: 'warning',
        title: "",
        text: "Platforma imkoniyatlaridan to'liq foydalanish uchun bot orqali avtorizatsiyadan o'ting!",
        footer: "<a href='https://t.me/Hokimakademiya_bot' target='_blank'>Avtorizatsiyadan o'tish</a>",
        showConfirmButton: false,
        showCloseButton: true
    })
    const navigator = useNavigate();
    let [authTokens, setAuthTokens] = useState(() =>
        localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null)
    let [user, setUser] = useState(() =>
        localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    )

    let loginUser = async (e) => {
        e.preventDefault()
        let res = await axios.post(`${BASE_API_URL}/login/`,
            { username: e.target.username.value, password: e.target.password.value }

        )
        console.log(res.data);
        if (res.data.success === true) {
            // if (res.data.user.telegram_channel) {
            setAuthTokens(res.data.token)
            setUser(res.data.user)
            localStorage.setItem('authTokens', JSON.stringify(res.data.token))
            localStorage.setItem('user', JSON.stringify(res.data.user))
            localStorage.setItem('maktab', JSON.stringify(res.data.user.status))
            localStorage.setItem('promo', JSON.stringify(res.data.user.is_used_promocode))
            navigator("/kabinet")
            // } //else{
                // Telegram()
            // }
            // console.log(res)
        } else {
            // alert(res.data.error);
            notify()
        }
    }

    let logOutUser = () => {
        setAuthTokens(null)
        setUser(null)
        localStorage.removeItem('authTokens')
        localStorage.removeItem('user')
        localStorage.removeItem('maktab')
        navigator('/')
    }

    let contextData = {
        user: user,
        authTokens: authTokens,
        setAuthTokens: setAuthTokens,
        setUser: setUser,
        loginUser: loginUser,
        logOutUser: logOutUser
    }

    return (
        <AuthContext.Provider value={contextData}>
            <ToastContainer />
            {children}
        </AuthContext.Provider>
    )
}