import React from 'react'
import { Button, Container, ImgWrapper, MAxContainer, Text, TextWrapper, Title, Wrapper } from './style'
import LeftPArent from '../../../assets/images/leftparent.webp';
import {useNavigate} from 'react-router-dom'

const ParentsBlock = () => {
  const navigate = useNavigate()
  return (
    <Container>
        <MAxContainer>
            <Wrapper>
                <TextWrapper>
                    <Title>Ota-onalar uchun</Title>
                    <Text>Farzandingiz muvaffaqiyatini kuzatib borishingiz uchun muqobil platforma!</Text>
                    <Button onClick={()=>navigate('/parents/login')}>Boshlash</Button>
                </TextWrapper>
                <ImgWrapper>
                    <img src={LeftPArent} alt="" />
                </ImgWrapper>
            </Wrapper>
        </MAxContainer>
    </Container>
  )
}

export default ParentsBlock