import React, { useState } from 'react'
import { ContainerBlock } from '../ParentsRegister/style';
import { Box, Input, InputLabel, RegisterTitle, Wrapper } from '../RegisterPage/Pages/style';
import { ButtonWrapper, ButtonWrapper1, Container, ContentWrapper, Image1, Image2, Image3, Image4, Image5, Image6, Nextbutton } from '../RegisterPage/style';
//images
import Pic1 from '../../assets/images/register/pic1.png';
import Pic2 from '../../assets/images/register/pic2.png';
import Pic3 from '../../assets/images/register/pic3.png';
import Pic4 from '../../assets/images/register/pic4.png';
import Pic5 from '../../assets/images/register/pic5.png';
import Pic6 from '../../assets/images/register/pic6.png';
import Swal from 'sweetalert2';
import axios from 'axios';
import { BASE_URL } from '../../constants/base_url';
import { useNavigate } from 'react-router-dom';

const ParentLogin = () => {
    const [login, setlogin] = useState('');
    const [parol, setparol] = useState('');
    const navigate = useNavigate()

    async function onSubmit(e){
        e.preventDefault();
        let data = new FormData();
        data.append('username',login);
        data.append('password',parol);
        
        try {
          const res = await axios.post(BASE_URL + "/api/v1/parent/login/",data);
          if(res){
            if(res.data?.success){
              localStorage.setItem("parent_token",JSON.stringify(res.data?.token))
              localStorage.setItem("user_parent",JSON.stringify(res.data?.user))
              localStorage.removeItem('phone_parent');
              navigate('/dashboard_parents');
            }else{
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Login yoki parol xato kiritildi",
                confirmButtonText:"Qaytadan to'ldirish"
              })
            }
            
            
          }
        } catch (err) {
          
        }
      }
  return (
    <Container>
      <ContentWrapper>
        <form onSubmit={onSubmit}>
        <ContainerBlock>
          <RegisterTitle>Kirish</RegisterTitle>
          <Wrapper>
            <Box>
              <InputLabel> Login * </InputLabel>
              <Input required value={login} onChange={(e)=>setlogin(e.target.value)} />
            </Box>
            <Box>
              <InputLabel> Parol *</InputLabel>
              <Input required type={'password'} placeholder="Parol 5ta belgidan ko'p bo'lishi kerak" value={parol} onChange={(e)=>setparol(e.target.value)}  />
            </Box>
          </Wrapper>
          <ButtonWrapper1 style={{marginTop:40}}>
            <Nextbutton >Kirish</Nextbutton>
           </ButtonWrapper1>
           <ButtonWrapper1>
                <a href="/parents/phone">Ro'yhatdan o'tish</a>
           </ButtonWrapper1>
          </ContainerBlock>
        </form>
      </ContentWrapper>
      <Image1 src={Pic1} />
      <Image2 src={Pic2} />
      <Image3 src={Pic3} />
      <Image4 src={Pic4} />
      <Image5 src={Pic5} />
      <Image6 src={Pic6} />
    </Container>
  )
}

export default ParentLogin