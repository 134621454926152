import React, { useEffect, useState } from 'react'
import { Box, Container, Input, InputLabel, InputLabelError, InputLabelSuccess, RegisterTitle, Wrapper } from './style'
import {useDispatch} from 'react-redux';

const Part1 = ({loginerror,login,setlogin,parol,setparol,setparolconfirm,parolconfirm,passwordtext,passwordMatch}) => {
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch({type:'REGISTER_1',payload:{login:login,parol:parol}})
  }, [login,parol]);
  

  return (
    <Container>
      <RegisterTitle>Ro’yhatdan o’tish</RegisterTitle>
      <Wrapper>

        <Box>
          <InputLabel> Login * </InputLabel>
          <Input value={login} onChange={(e)=>setlogin(e.target.value)} />
          {
            login?.length < 3 ? null :
            loginerror?.includes('allaqachon')  ? 
            <InputLabelError >{loginerror}</InputLabelError> :
            <InputLabelSuccess >{loginerror}</InputLabelSuccess>
          }
        </Box>
        <Box>
          <InputLabel> Yangi parol kiriting *</InputLabel>
          <Input type={'password'} placeholder="Parol 5ta belgidan ko'p bo'lishi kerak" value={parol} onChange={(e)=>setparol(e.target.value)}  />
        </Box>
        <Box>
          <InputLabel> Parolni qayta kiriting * </InputLabel>
          <Input type={'password'} placeholder="Parol 5ta belgidan ko'p bo'lishi kerak" value={parolconfirm} onChange={(e)=>setparolconfirm(e.target.value)}  />
          {parolconfirm?.length > 2  ? (!passwordMatch ? <InputLabelError >{passwordtext}</InputLabelError> : <InputLabelSuccess >{passwordtext}</InputLabelSuccess>) : null}
        </Box>
      </Wrapper>
    </Container>
  )
}

export default Part1