import React, { useEffect, useState } from 'react'
import { Box, Container, Input, InputLabel, Notes, RegisterText, RegisterTitle, Wrapper } from './style'
import {useDispatch} from 'react-redux';
import { ButtonWrapper, Nextbutton } from '../style';

const Part2 = ({setCurrent,current}) => {

  const [familya,setfamilya] = useState('');
  const [ism, setism] = useState('');
  const [sharif, setsharif] = useState('');
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch({type:'REGISTER_2',payload:{familya:familya,ism:ism,sharif:sharif}})
  }, [familya,ism,sharif]);

  function next_handler(e) {
    e.preventDefault()
    setCurrent(current+1)
  }

  return (
    <form  onSubmit={(e)=>next_handler(e)} >

    <Container>
      <RegisterTitle>Ro’yhatdan o’tish</RegisterTitle>
      <Wrapper>
        <RegisterText>FISH</RegisterText>
        <Box>
          <InputLabel> Familya </InputLabel>
          <Input required={true} value={familya} onChange={(e)=>setfamilya(e.target.value)} />
        </Box>
        <Box>
          <InputLabel> Ism </InputLabel>
          <Input  required={true} value={ism} onChange={(e)=>setism(e.target.value)}  />
        </Box>
        <Box>
          <InputLabel> Sharif </InputLabel>
          <Input required={true} value={sharif} onChange={(e)=>setsharif(e.target.value)} />
        </Box>
        <Notes>
        Pasport ma’lumotiga zid bo’lgan har qanday ism yoki nom orqali ro’yxatdan o’tgan foydalanuvchining arizasi qabul qilinmaydi!
        </Notes>
      </Wrapper>
      <ButtonWrapper style={{marginTop:30}}>
          <Nextbutton type='submit'>Keyingisi</Nextbutton>
        </ButtonWrapper>
    </Container>
    </form>

  )
}

export default Part2