import React, { useState } from 'react'
import { Box, BoxCode, ContainerT, InputLabel, InputPhone, InputPhoneCode, RegisterTitle, Wrapper } from './Pages/style'
import { ButtonWrapper, Container, ContentWrapper, Image1, Image2, Image3, Image4, Image5, Image6, Nextbutton } from './style'
import {useNavigate,useLocation, Link} from 'react-router-dom'

//images
import Pic1 from '../../assets/images/register/pic1.png';
import Pic2 from '../../assets/images/register/pic2.png';
import Pic3 from '../../assets/images/register/pic3.png';
import Pic4 from '../../assets/images/register/pic4.png';
import Pic5 from '../../assets/images/register/pic5.png';
import Pic6 from '../../assets/images/register/pic6.png';
import Swal from 'sweetalert2';
import { BASE_API_URL } from '../../constants/base_url';
import axios from 'axios';

import ReactInputVerificationCode from 'react-input-verification-code';


const ConfirmCode = () => {
    const [code, setCode] = useState('');
    const navigate = useNavigate();
    const location = useLocation()
    const [codeError, setCodeError] = useState(false);


    async function confirm_code(e){
        e.preventDefault()
        let formdata = new FormData();
        formdata.append('phone',location?.state);
        formdata.append('code',code);
        try {
            const res = await axios.post(BASE_API_URL + "/confirm-code/cjanbksjcnkajkqwee/",formdata);
            // console.log(res)
            if(res.data?.success){
                navigate('/register');
            }else{
                Swal.fire({
                    title:"Xatolik",
                    text:"Kod xato iltimos tekshirib qaytadan kiriting",
                    icon:'warning'
                })
                setTimeout(() => {
                    setCodeError(true)
                }, 500);
                
            }
        } catch (err) {
            
        }
    }


    return (
        <Container>
            
            
            <ContentWrapper>
                <form onSubmit={confirm_code}>
                    <ContainerT>
                        <RegisterTitle>Ro’yhatdan o’tish</RegisterTitle>
                            <Wrapper>
                                <Box>
                                    <InputLabel style={{textAlign:'center'}}> Tasdiqlash kodini tering </InputLabel>
                                </Box>
                                <BoxCode >
                                    <ReactInputVerificationCode 
                                        passwordMask={'0'}
                                        length={6}
                                        onChange={(e)=>{setCode(e)}}  
                                        placeholder=''
                                        autoFocus={true}
                                    />
                                </BoxCode>
                                <ButtonWrapper style={{marginTop:10, marginBottom:0}}>
                                    <Nextbutton type='submit'>
                                        Tasdiqlash
                                    </Nextbutton>
                                </ButtonWrapper>
                                { codeError
                                    ?<Link to={"/phone"}>Kodni qayta olish</Link>
                                    :""
                                }
                                
                            </Wrapper>
                    </ContainerT> 
                </form>
            </ContentWrapper>
        
            <Image1 src={Pic1} />
            <Image2 src={Pic2} />
            <Image3 src={Pic3} />
            <Image4 src={Pic4} />
            <Image5 src={Pic5} />
            <Image6 src={Pic6} />
        </Container>
    )
}

export default ConfirmCode