
import Dialog from '@mui/material/Dialog';
import { ContentWrapper, ContentWrapper1 } from '../../RegisterPage/style';
import { Box, BoxCheckFlex, BoxSorovnoma, Container, Input, InputDate, InputLabel,  InputLabelNew,  RadioMe,  RegisterTextBlack, RegisterTextBlack1, RegisterTitle, TextArea, Wrapper, Wrapper1 } from '../../RegisterPage/Pages/style';
import { Radio } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import {useNavigate} from 'react-router-dom'
import { BoxLoading } from 'react-loadingg';
import { Rating } from '@mui/material';
import { ButtonWrapper, Nextbutton } from '../../RegisterPage/style';
import { BASE_URL } from '../../../constants/base_url';
import { ContainerC, WrapAll } from './style';
import {  message, Space } from 'antd';




const SurverModal = ({isShown,setIsShown}) => {
    const [loading, setloading] = useState(true);
    const [Survey, setSurvey] = useState({});
    const [Questions, setQuestions] = useState([]);
    const [value1, setValue1] = useState(0);
    const [Keys, setKeys] = useState([]);
    const [Multis, setMultis] = useState([]);
    let registertoken = JSON.parse(localStorage.getItem('authTokens'));
    
    const [messageApi, contextHolder] = message.useMessage();
    
    const success = () => {
      messageApi.open({
        type: 'success',
        content: 'So‘rovnomangiz muvaffaqiyatli to‘ldirildi',
      });
    };
  
    async function get_surveys(){
        try {
          const res = await axios.get(BASE_URL+`/api/v1/surveys/surveys/for/students/`,{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${registertoken?.access}`
            }
          });
          if(res){
            setSurvey(res?.data)
          }
        } catch (err) {
          
        }
    }
  
    
  
    async function gettingsurveyquestions(){
  
      try {
        const res = await axios.get(BASE_URL+`/api/v1/surveys/questions/${Survey?.id}/`);
          if(res){
            setloading(false)
            setQuestions(res?.data)
            let filtered = res?.data?.map((item)=>{
              return {key:item.key,type:item.type_field}
            })
            setKeys(filtered)
          }
        
      } catch (err) {
        
      }
    }
  
  
    function choice_maker(data){
      let option = []
      data?.map((item)=>{
        option.push({ value: item, label: item })
      })
      return option
    }
  
  
    useEffect(() => {
      if(Survey?.id){
        gettingsurveyquestions();
      }
    }, [Survey]);
  
  
    useEffect(() => {
      get_surveys()
    }, []);
  
  
    useEffect(() => {
     
    }, [loading]);
  
    function name(e) {
      e.preventDefault()
      let word = document.getElementsByName('sorovnoma');
      let data = {
        survey: Survey?.id,
        answers: []
      };
  
  
  
  
      word.forEach((el)=>{
        Keys.map((item)=>{
          if(item.type === 4){
            data.answers.push({key:item.key,value:Multis?.map((r)=>r.value).join(",")})
          }else{
            data.answers.push({key:item.key,value:el[item.key].value});
          }
        })
      })
  
  
      // console.log('surveys', data);
  
      try{
        axios.post(BASE_URL+'/api/v1/surveys/create-answer/',data,{
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${registertoken?.access}`
        }
        }).then((res)=>{
          setIsShown(false);
          success()
          // console.log('res', res)
        })
      }catch{}
      
    }
  
  return (
    <ContainerC>
       {contextHolder}
        <Dialog  open={isShown}  >
            <WrapAll >
                <ContentWrapper1 >
                    {loading ?
                    <Container>
                        <Wrapper1><h1>Yuklanmoqda...</h1> <BoxLoading /></Wrapper1>
                    </Container> :
                    <Container>
                        <RegisterTitle>{Survey?.name}</RegisterTitle>
                        <Wrapper >
                            <RegisterTextBlack1>{Survey?.description}</RegisterTextBlack1>
                            <form name='sorovnoma' onSubmit={name}>
                            {
                                Questions?.map((item,i)=>(
                                <BoxSorovnoma key={i} >
                                    <InputLabel>{item.label}</InputLabel>
                                    {
                                    item.type_field === 0 ? <InputDate required={item.required} name={item.key} placeholder={item.help_text}  type={'text'} /> : 
                                    item.type_field === 1 ? <InputDate required={item.required} name={item.key} placeholder={item.help_text} type={'number'} /> : 
                                    item.type_field === 2 ? <Radio.Group required={item.required} name={item.key} ><BoxCheckFlex> {item.choices?.map((r,ind)=>(<RadioMe key={ind} value={r}>{r}</RadioMe>))}</BoxCheckFlex> </Radio.Group> : 
                                    item.type_field === 3 ? <Select  name={item.key} placeholder={item.help_text}  isSearchable={false}  options={choice_maker(item.choices)}  /> :  
                                    item.type_field === 4 ? <Select name={item.key} placeholder={item.help_text} isSearchable={false} isMulti onChange={(e)=>setMultis(e)}  options={choice_maker(item.choices)}  className="basic-multi-select"  classNamePrefix="select" /> : 
                                    item.type_field === 5 ? <TextArea required={item.required} name={item.key} placeholder={item.help_text}  /> : 
                                    item.type_field === 6 ? <InputDate required={item.required} name={item.key} type='url' /> : 
                                    item.type_field === 7 ? <InputDate required={item.required} name={item.key} placeholder={item.help_text} type={'email'} /> : 
                                    item.type_field === 8 ? <InputDate required={item.required} name={item.key} type={'date'} /> : 
                                    item.type_field === 9 ? <Rating  name={item.key}   value={value1} size='large' style={{marginTop:10}}  onChange={(event, newValue) => {  setValue1(newValue);  }} /> : null
                                    }
                                </BoxSorovnoma>
                                ))
                            }
                            <ButtonWrapper>
                                <Nextbutton type='submit'>Tasdiqlash</Nextbutton>
                            </ButtonWrapper>
                            </form>
                        </Wrapper>
                    </Container>}
                </ContentWrapper1>
            </WrapAll>
        </Dialog>
  </ContainerC>
  )
}

export default SurverModal




