import React from "react";
import oqituvchi2 from "../../../images/home/homeOqituvchi/oqituvchi.webp";

const Oqituvchi = () => {
  return (
    <div className="oqituvchi">
      <div className="oqituvchi2" id="oqituvchi">
        <div className="oqituvchi__inner2">
          <div className="oqituvchi2__info">
            <h2>O‘QITUVCHILAR UCHUN</h2>
            <p>
              Yaxshi o‘qituvchi yaxshi o‘rgatgan emas, balki o‘zi ham o‘qib
              o‘rganadigan o‘qituvchidir
            </p>
            <a href="/login/oqituvchi">Boshlash</a>
          </div>
          <img className="oqituvchi2__img" src={oqituvchi2} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Oqituvchi;
