import React, { useEffect, useState } from 'react';
import { Box, BoxCheck, Container, Input, InputDate, InputLabel, RadioMe, RegisterText, RegisterTitle, Wrapper } from './style'
import { Radio } from 'antd';
import { useDispatch } from 'react-redux';
import axios from 'axios'
import { BASE_API_URL } from '../../../constants/base_url';
import { ButtonWrapper, Nextbutton } from '../style';

const Part4 = ({setCurrent,current}) => {
  const [value, setValue] = useState(1);
  const [age, setage] = useState('');
  const [Langeuges, setLangeuges] = useState([]);
  const dispatch = useDispatch()


  const onChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    try {
      axios.get(BASE_API_URL+'/get-languages/').then((res)=>{
        setLangeuges(res.data)
      })
    } catch (err) {
      
    }
  }, []);


  useEffect(()=>{
    dispatch({type:'REGISTER_4',payload:{age:age,lan:value}})
  },[age,value])

  function next_handler(e) {
    e.preventDefault()
    setCurrent(current+1)
  }

  return (
    <Container>
      <form onSubmit={(e)=>next_handler(e)} style={{width:'100%',maxWidth:420}}>
      <RegisterTitle>Ro’yhatdan o’tish</RegisterTitle>
      <Wrapper>
        <RegisterText>Yosh doirangiz ?</RegisterText>
        <Box>
          <InputDate 
            type='date'
            value={age}
            onChange={(e)=>setage(e.target.value)}
            required
          />
        </Box>
        <RegisterText>Ta'lim tilini tanlang ?</RegisterText>
        <Radio.Group onChange={onChange} value={value} required >
          <BoxCheck>
            {
              Langeuges?.map((item,i)=>(
                <RadioMe checked={false} defaultChecked={false} required  key={i} value={item.id}>{item.name}</RadioMe>
              ))
            }
          </BoxCheck>
        </Radio.Group>
      </Wrapper>
        <ButtonWrapper>
          <Nextbutton type='submit'>Keyingisi</Nextbutton>
        </ButtonWrapper>
        </form>
    </Container>
  )
}

export default Part4