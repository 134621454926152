import React, { useEffect, useState } from 'react';
import Slider from './Slider';
import { Link } from "react-router-dom";
import useAxios from "../../../api/useAxios";

import Center1 from "../../../assets/images/Kabinet/center1.webp";
import Center2 from "../../../assets/images/Kabinet/center2.webp";
import Center4 from "../../../assets/images/Kabinet/center4.webp";
import Wave1 from "../../../assets/images/Kabinet/wave_maktab.webp";
import Wave4 from "../../../assets/images/Kabinet/wave_oqituvchi.webp";
import Wave2 from "../../../assets/images/Kabinet/wave_abiturient.webp";


const Main = () => {
  const api = useAxios()
  const status = localStorage.getItem("maktab")
  const num = status === "1" ? "1" 
    : status === "2" ? "2" 
    : status === "3" ? "2" 
    : "4"
  const image = 
      status === "1" ? Center1 
    : status === "2" ? Center2
    : status === "3" ? Center2
    : Center4
  const bg = 
      status === "1" ? Wave1 
    : status === "2" ? Wave2
    : status === "3" ? Wave2
    : Wave4
  const scrollTop = () =>{
    window.scrollTo(0 ,0)
  }
  const [recommends_data, setRecommends_data] = useState([])
  const [category_data, setCategory_data] = useState([])
  useEffect(() =>{
    api.get(`/course-home/`)
    .then((res)=>{
      // console.log(res.data.data)
      var cat = res.data.data.category.filter((v)=>{
        if (v.course.length !== 0) {
          return res.data.data.category
        }
      })
      setRecommends_data(res.data.data.recommends)
      setCategory_data(cat)
    })
  }, [])
  return ( 
    <React.Fragment>
    <div className="kabinet__main">
      {recommends_data.length === 0 
      ? <div className='no__data'></div> 
      : <div className="kabinet__slider">
          <h1 className='slider__title' id={`slider__title${num}`}>Siz uchun tavsiya etiladi</h1>
          <Slider data={recommends_data}/>
        </div>}
    </div>

    <div className="kabinet__tavsiya" id={`kabinet__tavsiya${num}`}>
      <div className="kabinet__main">
        <div className='tavsiya__content'>
          <div>
            <h1>Dasturlashni o'rganing</h1>
            <p>Ta'lim kelajak poydevori, chunki ertangi kun unga bugun tayyorlanayotganlarga tegishli.</p>
            <Link onClick={scrollTop} to="/kabinet/kurslar"><span>Ko'rish</span></Link>
          </div>
          <div>
            <img src={image} alt="" />
          </div>
          
        </div>
      </div>
      <div className='kabinet__bg'>
        <img src={bg} alt="" />
      </div>
    </div>
    {category_data?.map((item, index)=>{
      return(
        <div className="kabinet__main" key={index}>
          <div className="kabinet__slider">
            <h1 className='slider__title' id={`slider__title${index%4+1}${num}`}>{item.name}</h1>
            <Slider data={item.course}/>
          </div>
        </div>
      )
    })}

    {/* <div className="kabinet__main">
      <div className="kabinet__slider">
        <h1 className='slider__title' id={`slider__title1${num}`}>Dasturlash</h1>
        <Slider data={recommends_data}/>
      </div>
    </div>

    <div className="kabinet__main">
      <div className="kabinet__slider">
        <h1 className='slider__title' id={`slider__title2${num}`}>Matematika</h1>
        <Slider data={recommends_data}/>
      </div>
    </div>

    <div className="kabinet__main">
      <div className="kabinet__slider">
        <h1 className='slider__title' id={`slider__title3${num}`}>Fizika</h1>
        <Slider data={recommends_data}/>
      </div>
    </div>

    <div className="kabinet__main">
      <div className="kabinet__slider">
        <h1 className='slider__title' id={`slider__title4${num}`}>Dasturlash</h1>
        <Slider data={recommends_data}/>
      </div>
    </div> */}
      
    </React.Fragment>
   );
}
 
export default Main;