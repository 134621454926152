import React, { useEffect, useState } from 'react';
import Main from './Main';
import { Modal } from 'antd';
import Header from './Header';
import { ErrorIcon } from './Detallar/icons';
import useAxios from "../../../api/useAxios";
import { SyncOutlined } from '@ant-design/icons';
import SurverModal from './SurverModal';
import SmallModal from './SmallModal';
import Swal from 'sweetalert2';

const KabinetHome = () => {
  const api = useAxios()
  const status = localStorage.getItem("maktab")

  const user = localStorage.getItem("promo") === "false" ? false : true
  const num = status === "1" ? "1" 
    : status === "2" ? "2" 
    : status === "3" ? "2" 
    : "4"
  const [modal1, setModal1] = useState(!user);
  const [modal2, setModal2] = useState(false);
  const [load, setLoad] = useState(false);
  const [errorText, seterrorText] = useState('');
  const handleOk1 = () => {
    setModal1(false);
    setModal2(true);
  };
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const okText = load ? <SyncOutlined spin /> : "Saqlash"

  //surveys
  const [surveyShow, setsurveyShow] = useState(false);
  const [nextsurveyshow, setnextsurveyshow] = useState(false);


  const handleOk2 = () => {
    setLoad(true)
    var url = "/change-password-first"
    const data ={
      password: password,
      username: username
    }
    // let formData = new FormData()
    // formData.append(
    //   "pasword",password
    // )
    // formData.append(
    //   "username",username
    // )
    // console.log(formData)
    api.put(`${url}/`, data)
    .then((res)=>{
      if(res.data.success){
        localStorage.setItem('promo', res.data.user.is_used_promocode)
        setTimeout(() => {
          setLoad(false)
          setModal2(false)
        }, 1000);
      }else{
        seterrorText('Bu username mavjud!')
        setLoad(false)
      }
    })
  };




  const Last_Login = async () => {
    try {
      const res = await api.get('/last-login/');

      if (res.data?.survey===false) {
        Swal.fire({
          title: 'Eslatma!',
          text: "Sayt imkoniyatlaridan foydalanish uchun, so‘rovnomani to‘ldirishingiz kerak!",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'To‘ldirish'
        }).then((result) => {
          if (result.isConfirmed) {
            setnextsurveyshow(true)
          }
        })
      }
    } catch (err) {
      console.log(err);
    }
  }


  useEffect(() => {
    Last_Login()
  }, []);
  // console.log(userId)
  return ( 
    <React.Fragment>
      <SurverModal isShown={nextsurveyshow} setIsShown={setnextsurveyshow} />
      <Modal
        title="Hafsizlikni kuchaytirish maqsadida parolingizni yangilang"
        visible={modal1}
        onOk={handleOk1}
        closable={false}
        okText="Parolni yangilash"
        cancelButtonProps={false}
        className={`password__change password__${num}`}
        
      >
        <ErrorIcon/>
      </Modal>
      <Modal
        title="Parolni yangilash"
        visible={modal2}
        closable={false}
        // okText="Saqlash"
        okText={okText}
        onOk={handleOk2}
        className={`password__change password__${num}`}
        
      >
        <p>User name</p>
        <span style={{color:'red',wordBreak:'break-word',fontSize:13,width:'300px'}}>{errorText}</span>
        <input type="text" onChange={(e)=>{
          setUsername(e.target.value)
          seterrorText('')
        }} placeholder='Username'/>
        <p>Parol</p>
        <input type="password"  onChange={(e)=>setPassword(e.target.value)} placeholder='8 xonadan iborat bo’lish kerak'/>
      </Modal>
      <Header/>
      <Main/>
    </React.Fragment>
   );
}
 
export default KabinetHome;