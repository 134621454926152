let initialState = {
    login: null,
    parol: null,
    familya: null,
    ism: null,
    sharif: null,
    viloyat: null,
    shaxar: null,
    mahalla: null,
    yosh: null,
    talimtili: null,
    type: null,
    fan1: null,
    fan2: null,
    fan3: null,
    fan4: null,
    fan5: null,
    sportturi: null,
    sanatturi: null,
    tadbirkorlik: null,
    zamonaviy: null,
    oliygoh: null,
    otmlar: null,
    oliytalimtili: null,
    taklif: null
}

const RegisterReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'REGISTER_1':
            let { login, parol } = action.payload;
            state.login = login;
            state.parol = parol;
            return state
        case 'REGISTER_2':
            let { familya,ism,sharif } = action.payload;
            state.familya = familya;
            state.ism = ism;
            state.sharif = sharif;
            return state
        case 'REGISTER_3':
            let { viloyat,tuman,mahalla } = action.payload;
            state.viloyat = viloyat;
            state.shaxar = tuman;
            state.mahalla = mahalla;
            return state
        case 'REGISTER_4':
            let { age,lan } = action.payload;
            state.yosh = age;
            state.talimtili = lan;
            return state;
        case 'REGISTER_5':
                let { type,fan1,fan2,fan3,fan4,fan5 } = action.payload;
                state.type = type;
                state.fan1 = fan1;
                state.fan2 = fan2;
                state.fan3 = fan3;
                state.fan4 = fan4;
                state.fan5 = fan5;
                break;
        default:
            return state;
    }
}

export default RegisterReducer;