import styled from 'styled-components'


export const Container = styled.div`
    width:100%;
    /* border:1px solid red; */
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    /* background-color: rgba(22,22,22,0.2); */
    position: relative;
`

export const ContentWrapper = styled.div`
   /* border: 1px solid red; */
   width:100%;
   margin-top: 100px;
   max-width:600px;
   min-height: 300px;
   padding: 20px 0px;
`

export const ContainerBlock = styled.div`
    width:100%;
    display:flex;
    justify-content:flex-start;
    /* border:1px solid red; */
    align-items: center;
    flex-direction: column;
    
`