import React from 'react'
import Abt from "../../../assets/images/Abiturient/Abiturient.webp"
import Abt2 from "../../../assets/images/Abiturient/Abiturient2.png"
import Ab1 from "../../../assets/images/Abiturient/ab_a1.png"
import Ab2 from "../../../assets/images/Abiturient/ab_a2.png"
import Ab3 from "../../../assets/images/Abiturient/ab_a3.png"

const Abituriyent = () => {
  return (
    <div className='bgwhite'>
      <div className='abiturient__container' id='abiturient'>
        <img className='ab__img' src={Abt} alt="" />
        <img className='ab__img' src={Abt2} alt="" />

        <h1>ABITURIYENTLAR <br /> UCHUN</h1>
        <h3>Maqsad sari yo‘l shu yerdan boshlanadi</h3>
        <a className='link__animated' href="/login/abiturient">Boshlash</a>
        <img src={Ab1} className='ab__animation1' style={{ "--i": "0" }} alt="" />
        <img src={Ab2} className='ab__animation2' style={{ "--i": "1" }} alt="" />
        <img src={Ab3} className='ab__animation3' style={{ "--i": "2" }} alt="" />
      </div>
    </div>
  )
}

export default Abituriyent