import React, { useContext } from 'react';
import AuthContext from '../../../context/AuthContext';

const Maktab = () => {
  const { loginUser } = useContext(AuthContext)
  return (
    <React.Fragment>
      <div className="p__maktab-wrapper">
        <div className="p__maktabi">
          <div className="p__maktabi-inner">
            <h2>Prezident maktabiga kiruvchilar uchun</h2>
            <p>Kirish</p>
            <form className='p__maktabi-form' action="/" onSubmit={(e) => loginUser(e)}>
              <label htmlFor="user">Login</label>
              <input type="text" name='username' id='user' placeholder='Login' />
              <label htmlFor="parol">Parol</label>
              <input name='password' type="text" placeholder='Parol' />
              <button type='submit'>Kirish</button>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Maktab;