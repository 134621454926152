import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { Box, Container, InputLabel, RegisterText, RegisterTextBlack, RegisterTitle, Wrapper } from './style';
import axios from 'axios'
import { BASE_API_URL } from '../../../constants/base_url';
import { useDispatch } from 'react-redux';
import BaseSelect from "react-select";
import FixRequiredSelect from "./RequiredSelect";
import { ButtonWrapper, Nextbutton } from '../style';

const options = [
  { value: 1, label: "Prezident Maktabi uchun" },
  { value: 2, label: "Abituriyent uchun" },
  { value: 3, label: "Chet tillarini o'rganuvchilar uchun" },
  { value: 4, label: "O'qituvchilar uchun" },
  { value: 5, label: "Barcha uchun" },
];


const Part5 = ({setCurrent,current}) => {
  const [type, settype] = useState(0);
  const [Subjects, setSubjects] = useState([]);
  const [fan1, setfan1] = useState('');
  const [fan2, setfan2] = useState('');
  const [fan3, setfan3] = useState('');
  const [fan4, setfan4] = useState('');
  const [fan5, setfan5] = useState('');
  const dispatch = useDispatch()


  async function get_subjects() {
    try {
      const res = await axios.get(BASE_API_URL + `/get-subjects/?status=${type}`)
      if (res) {
        let newoption = []
        res.data?.filter(item => {
          newoption.push({ value: item.id, label: item.name })
        })
        setSubjects(newoption);
      }
    } catch (err) {

    }
  }


  useEffect(() => {
    get_subjects()
    localStorage.setItem('maktab', JSON.stringify(type))
  }, [type]);

  useEffect(() => {
    dispatch({ type: 'REGISTER_5', payload: { type: type, fan1: fan1, fan2: fan2, fan3: fan3, fan4: fan4, fan5: fan5 } })
  }, [type, fan1, fan2, fan3, fan4, fan5])

  function next_handler(e) {
    e.preventDefault()
    setCurrent(current+1)
  }


  return (
    <Container>
      <RegisterTitle>Ro’yhatdan o’tish</RegisterTitle>
      <form onSubmit={(e)=>next_handler(e)}>
      <Wrapper>
        <RegisterText>O’zingiz uchun kerakli 5ta fanni tanlang</RegisterText>
        <Box>
          <InputLabel> Qaysi bo'lim uchun </InputLabel>
          <FixRequiredSelect SelectComponent={BaseSelect} 
            placeholder='Bo`limni tanlang'
            required
            isSearchable={false}
            options={options}
            onChange={(e) => {
              settype(e.value)
            }}
          />
        </Box>
        <Box>
          <InputLabel> Birinchi fan </InputLabel>
          <FixRequiredSelect SelectComponent={BaseSelect} 
            placeholder='Birinchi fan'
            required
            isSearchable={false}
            options={Subjects}
            onChange={(e) => { setfan1(e.value) }}
          />
        </Box>
        <Box>
          <InputLabel> Ikkinchi fan </InputLabel>
          <FixRequiredSelect SelectComponent={BaseSelect} 
            placeholder='Ikkinchi fan '
            required
            options={Subjects}
            onChange={(e) => { setfan2(e.value) }}
            isSearchable={false}
          />
        </Box>
        <Box>
          <InputLabel> Uchinchi fan </InputLabel>
          <FixRequiredSelect SelectComponent={BaseSelect} 
            placeholder='Uchinchi fan'
            required
            options={Subjects}
            isSearchable={false}
            onChange={(e) => { setfan3(e.value) }}

          />
        </Box>
        <Box>
          <InputLabel> Ixtiyoriy 1-fan </InputLabel>
          <FixRequiredSelect SelectComponent={BaseSelect} 
            placeholder='Ixtiyoriy 1-fan'
            options={Subjects}
            isSearchable={false}
            onChange={(e) => { setfan4(e.value) }}

          />
        </Box>
        <Box>
          <InputLabel> Ixtiyoriy 2-fan </InputLabel>
          <FixRequiredSelect SelectComponent={BaseSelect} 
            placeholder='Ixtiyoriy 2-fan'
            options={Subjects}
            isSearchable={false}
            onChange={(e) => { setfan5(e.value) }}

          />
        </Box>
        <RegisterTextBlack>
          Arizangiz uchun tashakkur! Kirishgacha
          bir qadam qoldi!
        </RegisterTextBlack>
        <ButtonWrapper>
          <Nextbutton type='submit'>Keyingisi</Nextbutton>
        </ButtonWrapper>
      </Wrapper>
        </form>
    </Container>
  )
}

export default Part5